.first-section {
    position: relative;

    .grid__col-left {
        display: flex;
        flex-direction: column;
    }

    &__slider {
        display: flex;
        overflow: auto;
        width: 100%;
        max-width: 800px;
        position: relative;
        gap: 15px;
        transition: .3s all ease-in-out;

        overscroll-behavior-inline: contain;
        -ms-scroll-snap-type: inline mandatory;
        scroll-snap-type: inline mandatory;
        scroll-behavior: smooth;
        scrollbar-width: none;

        img {
            height: 100%;
        }

        div {
            scroll-snap-align: start;
        }
    }

    &__main-img {
        flex: 1 0 100%;
        transition: all 0.5s ease-in-out;
        max-height: 500px;
    }

    &__sub-slider {
        display: flex;
        justify-content: center;
        gap: 10px;
        margin-top: 20px;
        width: 100%;
        max-width: 800px;
    }

    &__sub-images {
        @extend .reset-btn;
        width: 100%;
        cursor: pointer;

        img {
            border-radius: 10px;
        }

        &:focus {
            outline: 2px solid var(--text);
            transition: .3s all ease-in-out;
        }
    }

    &__title {
        margin-bottom: 50px;
    }

    h1 {
        @extend .h1;
        margin-bottom: 25px;
        text-transform: uppercase;
        width: 100%;
    }

    p {
        @extend .light-text;
    }

    &__buttons {
        display: flex;
        gap: 25px;
    }

    &__personal-accounts {
        display: flex;
        align-items: end;
        justify-content: space-between;
        margin-top: auto;
    }

    &__account-link {
        max-width: 238px;
        width: 100%;
    }

    &__column {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
}

@media (max-width: $first-bp) {

    .first-section {

        .grid {
            &__col-left {
                height: auto;
            }
        }

        &__main-img {
            max-width: 710px;
        }

        &__sub-images {
            max-width: 170px;
        }

        &__account-link {
            max-width: 210px;
        }
    }
}

@media (max-width: $second-bp) {
    .first-section {

        &__main-img {
            max-width: 640px;
        }

        &__sub-images {
            max-width: 152px;
        }

        &__account-link {
            max-width: 190px;
        }
    }
}

@media (max-width: $third-bp) {

    .first-section {

        &__title {
            margin-top: 60px;
            margin-bottom: 40px;
        }

        &__personal-accounts {
            margin-top: 40px;
            justify-content: start;
            gap: 40px;
        }

        &__buttons {
            gap: 50px;
        }

        &__main-img {
            max-height: 420px;
        }
    }
}

@media (max-width: $fourth-bp) {

    .first-section {

        &__buttons {
            flex-direction: column;
            gap: 20px;
        }

        &__personal-accounts {
            gap: 0;
            justify-content: space-between;
        }

        &__main-img {
            max-height: 271px;
        }
    }
}

@media (max-width: $fifth-bp) {

    .first-section {

        h1 {
            text-transform: none;
        }

        &__title {
            margin-top: 50px;
            margin-bottom: 30px;
        }

        &__personal-accounts {
            flex-direction: column;
            align-items: center;
            gap: 10px;
        }

        &__account-link {
            max-width: 100%;
        }

        &__main-img {
            max-height: 200px;
        }
    }
}

@media (max-width: $second-bp) {

    .admission-rules {

        .grid {
            &__col-left {
                width: 100%;
            }

            &__col-right {
                position: absolute;
                width: 0;
                height: 0;
            }
        }

        &__img {
            position: absolute;
            width: 0;
            height: 0;
        }

        &__content {

            h2, p {
                margin-bottom: 50px;
            }

            h3 {
                margin-bottom: 25px;
            }

            ul, ol {
                margin-bottom: 20px;

                li {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.articles {

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 100px;
    }

    &__all-news {
        display: flex;
        align-items: center;
        transition: 0.3s all ease-in-out;

        &:hover > span {
            color: var(--text-hover);
            transition: 0.3s all ease-in-out;

            & > svg {
                fill: var(--text-hover);
                transition: 0.3s all ease-in-out;
            }
        }
    }

    &__all-news-arrow {
        width: 24px;
        height: 24px;
    }

    &__all-news-text {
        margin-right: 30px;
        text-transform: lowercase;
    }

    &__view-cards {
        display: grid;
        grid-auto-flow: column;
        // justify-content: space-between;
        gap: 20px;
        padding-bottom: 10px;
        overflow-x: auto;
        overscroll-behavior-inline: contain;
        -ms-scroll-snap-type: inline mandatory;
        scroll-snap-type: inline mandatory;
        scroll-padding-inline: 20px;

        scrollbar-color: #008AD155 var(--light-background);
        scrollbar-width: thin;
        scroll-behavior: smooth;

        &::-webkit-scrollbar-thumb {
            height: 10px;
        }

        &::-webkit-scrollbar-track {
            background: var(--light-background);
        }

        &::-webkit-scrollbar-thumb {
            background: #008AD155;
        }

        article {
            scroll-snap-align: start;
        }
    }
}

@media (max-width: $fourth-bp) {

    .news-card:nth-child(3), .news-card:nth-child(4) {
        display: none;
        position: absolute;
        width: 0;
        height: 0;
    }
}

.grid {
    display: flex;

    &:last-child {
        margin-bottom: 0;
    }

    &__col-left {
        position: relative;
        width: 870px;

        &_pl {
            padding-left: 130px;
        }
    }

    &__col-right {
        position: relative;
        width: 770px;
        margin-left: 100px;
    }
}

@media (max-width: $first-bp) {
    .grid {

        &__col-left {
            width: 710px;

            &_pl {
                padding-left: 90px;
            }
        }

        &__col-right {
            width: 670px;
            margin-left: 40px;
        }
    }
}

@media (max-width: $second-bp) {
    .grid {

        &__col-left {
            width: 640px;

            &_pl {
                padding-left: 90px;
            }
        }

        &__col-right {
            width: 610px;
            margin-left: 30px;
        }
    }
}

@media (max-width: $third-bp) {
    .grid {
        flex-direction: column;

        &__col-left, &__col-right {
            width: 100%;
        }

        &__col-left {

            &_pl {
                padding-left: 0;
            }
        }

        &__col-right {
            margin-left: 0;
        }
    }
}

.areas-study {

    h2 {
        @extend .h2;
        margin-bottom: 100px;
    }

    &__card {
        padding-left: 130px;
    }

    &__img {
        position: sticky;
        top: 0;
        bottom: -50px;
    }

    &__direction-card {
        margin-bottom: 200px;
    }
}

@media (max-width: $first-bp) {
    .areas-study {

        &__card {
            padding-left: 90px;
        }
    }
}

@media (max-width: $second-bp) {
    .areas-study {

        .grid {
            &__col-left {
                width: 100%;
            }

            &__col-right {
                position: absolute;
                width: 0;
                height: 0;
            }
        }

        h2 {
            margin-bottom: 50px;
        }

        &__img {
            position: absolute;
            width: 0;
            height: 0;
        }

        &__direction-card {
            margin-bottom: 150px;
        }
    }
}

@media (max-width: $third-bp) {
    .areas-study {

        &__card {
            padding-left: 0;
        }

        &__direction-card {
            margin-bottom: 100px;
            padding-top: 37px;
        }
    }
}

.study-card {

    &__title {
        margin-bottom: 50px;
        padding-left: 130px;
        line-height: var(--text-nums-lh);
        position: relative;
    }

    &__id-class {
        position: absolute;
        font-size: var(--regular-text-size);
        font-weight: var(--regular-text-weight);
        bottom: -1px;
        left: 0;
    }

    &__info {
        margin-bottom: 10px;

        & > strong {
            font-weight: var(--default-text-weight);
        }
    }

    &__link {
        color: var(--text-hover);
        transition: .3s all ease-in-out;

        &:hover {
            color: var(--hower-btn);
        }
    }

    &__btn {
        margin-bottom: 100px;
    }

    &__direction-info {
        margin: 50px 0 100px 0;
        color: var(--text);
    }
}

@media (max-width: $first-bp) {

    .study-card {

        &__title {
            padding-left: 90px;
        }
    }
}

@media (max-width: $second-bp) {

    .study-card {

        &__direction-info {
            margin-bottom: 50px;
        }

        &__btn {
            margin-bottom: 50px;
        }
    }
}

@media (max-width: $third-bp) {

    .study-card {

        &__title {
            padding-left: 0;
        }

        &__id-class {
            bottom: calc(100% + 15px);
        }
    }
}

@media (max-width: $fourth-bp) {

    .study-card {

        &__title {
            line-height: var(--link-lh);
            margin-bottom: 30px;
        }

        &__id-class {
            bottom: calc(100% + 15px);
        }

        &__btn {
            width: 100%;
        }
    }
}

.direction-info {
    display: flex;
    justify-content: flex-start;

    &__group {
        margin-bottom: 50px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__text {
        @extend .regular-small-text;
        margin-bottom: 20px;
    }

    &__subtext {
        @extend .regular-small-text;
    }

    &__paid, &__cost {
        margin-left: 75px;
    }
}

@media (max-width: $second-bp) {

    .direction-info {

        &__paid, &__cost {
            margin-left: 50px;
        }
    }
}

@media (max-width: $fourth-bp) {

    .direction-info {
        flex-wrap: wrap;

        &__paid {
            margin-left: 30px;
        }

        &__cost {
            width: 100%;
            margin-left: 0;
            margin-top: 40px;
            display: flex;
            justify-content: space-between;
        }
    }
}

.military-department {
    padding: 100px 0;

    button {
        @extend .btn_transparent;
        margin-top: 50px;
    }
}

@media (max-width: $second-bp) {

    .military-department {

        .grid {
            flex-direction: column;
            padding-left: 90px;

            &__col-left, &__col-right {
                width: 100%;
                margin-left: 0;
            }

            &__col-left {
                &_pl {
                    padding-left: 0;
                }
            }
        }

        & .grid__col-right {
            padding-top: 100px;
        }
    }
}

@media (max-width: $third-bp) {

    .military-department {
        padding: 50px 0;

        .grid {
            padding-left: 0;
        }

        button {
            margin-top: 30px;
        }
    }
}

@media (max-width: $fourth-bp) {

    .military-department {

        button {
            width: 100%;
        }
    }
}
