.priem-comp {
    padding-bottom: 50px;

    &__section {
        padding: 50px 0;

        & > p {
            @extend .regular-text;
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }

            & > a {
                @extend .common-link;
            }
        }

        & > h4 {
            @extend .h4;
            margin-bottom: 15px;

            & > a {
                @extend .common-link;
            }
        }

        &:nth-child(4) > h4 {
            margin-top: 0;
            margin-bottom: 20px;
        }

        & > ul {
            @extend .common-ul-list;
        }
    }

    &__table {
        overflow-x: auto;

        & > table {
            @extend .table;
        }
    }

    h1 {
        @extend .h1;
        color: var(--text-light);
        width: 808px;
        margin-bottom: 50px;
    }

    &__subtitle {
        @extend .h3;
        color: var(--text-light);
        margin-bottom: 20px;
    }

    &__text {
        @extend .regular-text;
        color: var(--text-light);
        margin-bottom: 20px;
    }

    &__link {
        @extend .reset-link, .regular-text;
        color: var(--text-light);
        position: relative;

        &_selection {
            transition: .3s all ease-in-out;
            font-weight: var(--default-text-weight);
            line-height: var(--link-lh);

            &::before {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: var(--text-light);
                opacity: 0;
                transition: .3s all ease-in-out;
            }

            &:hover::before {
                opacity: 1;
            }
        }
    }

    &__group {
        display: flex;
    }

    &__section-title {
        margin-bottom: 50px;

        & h3 {
            @extend .h3;
        }

        & p {
            padding: 0;
            margin: 0;

            & strong, & b {
                font-weight: var(--regular-text-weight);
            }
        }
    }

    &__priem-places {
        width: 100%;
    }

    .select-info {
        margin-top: 50px;
    }
}

@media (max-width: $second-bp) {

    .priem-comp {
        &__group {
            flex-wrap: wrap;
            width: 100%;

            & .select-info {
                position: relative;
                width: 100%;
                margin-top: 0;
                top: 0;
            }

            & .abitur-nav {
                max-width: 100%;
                display: flex;
                padding-left: 0;
                flex-wrap: wrap;
                gap: 30px;

                &__item {
                    width: 300px;
                    margin-bottom: 0;
                }
            }
        }
    }
}

@media (max-width: $third-bp) {

    .priem-comp {
        &__group .abitur-nav {
            gap: 20px;
        }

        h1 {
            width: 100%;
            margin-bottom: 30px;
        }

        &__table {
            overflow-x: auto;
        }
    }
}

@media (max-width: $fourth-bp) {

    .priem-comp {

        .dropdown-list {
            &__link {
                width: 100%;
                justify-content: space-between;

                .angle {
                    margin-left: 0;
                }
            }
        }
    }
}

.priem-header {
    padding: 50px;
    background-color: var(--primary-background);
    position: relative;
    overflow: hidden;

    &__group {
        display: flex;
        width: 790px;
        justify-content: space-between;
    }

    &__col {
        display: flex;
        flex-direction: column;
        width: 50%;
    }

    &__svg {
        position: absolute;
        top: 10%;
        right: 12%;
        overflow: hidden;
    }

    &__section {
        margin-top: 50px;
        margin-bottom: 100px;
    }
}

@media (max-width: $first-bp) {
    .priem-header {

        &__svg {
            right: 5%;
        }
    }
}

@media (max-width: $second-bp) {
    .priem-header {
        padding: 50px 30px;

        &__svg {
            width: 350px;
            height: 350px;
            right: 1%;
            top: 11%;

            object {
                width: 100%;
            }
        }
    }
}

@media (max-width: $third-bp) {
    .priem-header {
      padding: 50px 15px;

        &__svg {
            display: none;
        }

        &__group {
            width: 100%;
        }

        &__col {
            &:first-child {
                width: calc(50% - 10px);
            }
        }
    }
}

@media (max-width: $fourth-bp) {

    .priem-header {
        &__group {
            flex-wrap: wrap;
        }

        &__col {
            width: 100%;

            &:first-child {
                width: 100%;
                margin-bottom: 50px;
            }

        }
    }
}

.priem-ul-links {
    @extend .reset-list;
    margin-top: 7px;

    &__item {
        margin-bottom: 25px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.priem-content {
    width: 1320px;
}

@media (max-width: $first-bp) {
    .priem-content {
      width: 1000px;
    }
}

@media (max-width: $second-bp) {
    .priem-content {
      width: 100%;
    }
}

.priem-exam, .priem-info, .priem-orders, .priem-docs, .priem-places, .priem-questions {
    transition: .3s all ease-in-out;
}

.dropdown-list+h4 {
    font-weight: var(--default-text-weight);
    margin-top: 25px;
}
