.news-page {

    &__articles {
        margin-bottom: 100px;
    }

    &__navigation {
        display: flex;
        align-items: center;
        margin-bottom: 100px;
    }
}

.cards {

    &__items {
        display: flex;
        flex-wrap: wrap;
        gap: 100px 20px;
    }
}

@media (max-width: $first-bp) {

    .cards {
        &__items {
            column-gap: 40px;
        }
    }
}

@media (max-width: $second-bp) {

    .cards {
        &__items {
            column-gap: 30px;
        }
    }
}

@media (max-width: $third-bp) {

    .cards {

        &__items {
            column-gap: 20px;
        }
    }
}

@media (max-width: $fourth-bp) {

    .cards {

        &__items {
            column-gap: 20px;
        }
    }

    .news-page {

        &__navigation {
            justify-content: center;
        }
    }
}
