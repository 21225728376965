*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    height: 100%;
    scroll-behavior: smooth;
    font-size: var(--font-size);
}

body {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    font-family: var(--main-font);
    background-color: var(--bg-color);
    color: var(--text);
    overflow-x: hidden;
    letter-spacing: var(--letter-spacing);
    font-size: var(--font-size);
}

picture {
    display: block;
    width: 100%;
    height: 100%;
    line-height: 0;
}

img {
    width: 100%;
    height: auto;
}
