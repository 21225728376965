.news-card {
    display: flex;
    flex-direction: column;
    width: 420px;

    &__img {
        display: flex;
        margin-bottom: 30px;
        width: 100%;
        height: 235px;
        justify-content: center;
        overflow: hidden;
        order: 0;
    }

    &__date {
        margin-bottom: 12px;
        cursor: auto;
        order: 1;
    }

    &__title {
        margin: 0;
        margin-bottom: 30px;
        transition: .3s all ease-in-out;
        order: 2;

        &:hover a {
            transition: .3s all ease-in-out;
            color: var(--text-hover);
        }
    }

    &__info {
        cursor: auto;
        order: 3;
    }
}

@media (max-width: $first-bp) {
    .news-card {
        width: 325px;
    }
}
@media (max-width: $second-bp) {
    .news-card {
        width: 225px;
    }
}

@media (max-width: $third-bp) {
    .news-card {
        // width: 300px;
        width: 193px;
    }
}

@media (max-width: $fourth-bp) {
    .news-card {
        width: 300px    ;
    }
}

.abitur-directions-card {
    width: 420px;
    // height: 300px;
    padding: 20px 30px;
    border: 1px solid var(--primary-background);
    box-shadow: 0 8px 16px rgba(0,0,0,.15);

    & > span {
        @extend .regular-text;
        margin-bottom: 10px;
    }

    & > h4 {
        @extend .h4;
        min-height: 44px;
        margin-bottom: 8px;
    }

    & > p {
        @extend .regular-text;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }

        & strong {
            &__title {
                @extend .h4;
                margin-bottom: 25px;
            }

            &__text {
                @extend .regular-text;
            }
        }
        
        span {
            font-weight: var(--default-text-weight);
        }

        a {
            @extend .reset-link;
            color: var(--text-hover);
            transition: .3s all ease-in-out;

            &:hover {
                color: var(--hower-btn);
            }
        }
    }
}

@media (max-width: $third-bp) {
    .abitur-directions-card {
        width: 300px;
        // height: 310px;
        padding: 20px 10px;

        & > h4 {
            min-height: 59px;
        }
    }
}

@media (max-width: $fourth-bp) {
    .abitur-directions-card {
        width: 100%;
    }
}

.abitur-proceed-card {
    width: 420px;

    & > h4 {
        @extend .h4;
        margin-bottom: 25px;
    }

    & > p {
        @extend .regular-text;
        margin-bottom: 20px;

        & a {
            @extend .reset-link, .regular-text;
            display: inline;
            color: var(--text-hover);
            transition: .3s all ease-in-out;

            &:hover {
                color: var(--hower-btn);
            }
        }
    }

    & > ul {
        @extend .common-ul-list;
    }
}

@media (max-width: $third-bp) {

    .abitur-proceed-card {
        width: 300px;
    }
}

@media (max-width: $fourth-bp) {

    .abitur-proceed-card {
        width: 100%;

        &__text {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.places-card {
    display: flex;
    width: 100%;
    padding: 30px;
    margin-bottom: 30px;
    border: 1px solid var(--primary-background);
    box-shadow: 0 8px 16px rgba(0,0,0,.15);

    &:last-child {
        margin-bottom: 0;
    }

    &__col-left {
        max-width: 318px;
        width: 100%;
        margin-right: 50px;
        height: auto;

        & h3 {
            @extend .h3;
        }

        & p {
            @extend .regular-text;
        }
    }

    &__group {
        display: flex;
        flex-direction: column;
        gap: 40px 0;
    }

    &__free, &__paid {

        & h4 {
            @extend .h4;
            margin-bottom: 10px;
        }

        & table {
            border-bottom: 1px solid var(--scroll);
            border-collapse: collapse;

            & td {
                padding: 10px 10px 7px;
                vertical-align: top;
                text-align-last: left;
            }
        }

        & thead {

            & tr {

                &:first-child {
                    background-color: var(--light-background);

                    & td {
                        font-size: var(--regular-text-size);
                    }
                }
            }

            & td {
                padding: 10px 10px 7px;
                vertical-align: top;
                text-align-last: left;
                font-size: var(--regular-text-small-size);
            }
        }

        & tbody {

            & td {
                font-size: var(--table-td-text-size);
                font-weight: var(--table-td-text-weight);
            }
        }
    }
}

@media (max-width: $first-bp) {

    .places-card {
      flex-wrap: wrap;

      &__col-left {
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
      }

      &__col-right {
        width: 100%;
      }
    }
}

@media (max-width: $third-bp) {

    .places-card {
        margin-bottom: 20px;
        padding: 10px;
    }
}

@media (max-width: $fourth-bp) {

    .places-card {

        &__free,
        &__paid {
            overflow-x: auto;
        }
    }
}

.honored-card {
    margin-bottom: 100px;

    &:last-child {
        margin-bottom: 0;
    }

    &__img {
        width: 572px;
    }

    & p {
        @extend .regular-text;
        margin-bottom: 20px;
        padding-bottom: 20px;

        &:last-child {
            padding-bottom: 100px;
        }
    }
}

@media (max-width: $second-bp) {

    .honored-card {
        .grid {
            &__col-right {
                width: 460px;
                margin-left: 20px;
            }

            &__col-left {
                width: 480px;
            }
        }

        &__img {
            width: 460px;
        }
    }
}

@media (max-width: $third-bp) {

    .honored-card {

        & .grid {
            flex-direction: column-reverse;

            &__col-right {
                width: 100%;
                margin-left: 0;
            }

            &__col-left {
                width: 100%;
            }
        }

        &__img {
            width: 100%;
            margin-bottom: 50px;
        }
    }
}

@media (max-width: $fourth-bp) {

    .honored-card {

        &__img {
            width: 100%;
            margin-bottom: 50px;
        }
    }
}

.direction-card {
    margin-bottom: 100px;

    &:last-child {
        margin-bottom: 0;
    }

    &__header {
        display: flex;
        margin-bottom: 70px;
    }
    
    &__info {
        width: 900px;
        padding: 30px;
        background-color: var(--light-background);
    }

    // &__title {
    //     @extend .h3;
    //     margin-bottom: 10px;
    // }

    &__code {
        @extend .regular-text;
        display: inline-block;
        margin-bottom: 25px;
    }

    &__row {
        display: flex;
        margin-bottom: 70px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__group {
        width: 420px;

        & h4 {
            @extend .h4;
            margin-bottom: 25px;
        }

        & ul {
            @extend .common-ul-list;
        }
    }

    &__text {
        @extend .regular-text;
        margin-bottom: 10px;

        & span {
            font-weight: var(--default-text-weight);
        }
    }

    &__nums {
        display: flex;
        width: 420px;
    }

    &__free {
        margin-right: 65px;
    }

    &__count, &__subtext {
        @extend .regular-small-text;
    }

    &__count {
        margin-bottom: 20px;
    }

    &__img {
        width: 420px;
    }

    &__desc {
        width: 870px;
        margin-bottom: 50px;

        & p {
            @extend .regular-text;
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__cols {
        display: flex;
        flex-wrap: wrap;
        gap: 50px 30px;
    }

    h3 {
        @extend .h3;
        margin-bottom: 25px;
    }

    &:nth-child(1) {
        h3 {
            margin-bottom: 10px;
        }
    }

    &__edu, &__skills, &__subjects, &__projects, &__work, &__profession {
        width: 420px;

        & p {
            @extend .regular-text;
        }

        & ul {
            @extend .common-ul-list;
        }

        & ol {
            @extend .common-ol-list;
        }
    }
}

@media (max-width: $first-bp) {

    .direction-card {
        &__img {
            display: none;
        }

        &__info {
            width: 100%;
        }
    }
}

@media (max-width: $second-bp) {

    .direction-card {

        &__nums {
            width: auto;

            &:first-child {
                margin-right: 50px;
            }
        }

        &__free {
            margin-right: 50px;
        }
    }
}

@media (max-width: $third-bp) {

    .direction-card {
        &__desc {
            width: 100%;
        }
    }
}

@media (max-width: $fourth-bp) {

    .direction-card {
        &__info {
            padding: 30px 10px;
        }

        &__row {
            margin-bottom: 50px;
            flex-wrap: wrap;
        }

        &__group {
            width: 100%;

            &:first-child {
                margin-bottom: 30px;
            }
        }

        &__count {
            margin-bottom: 10px;
        }

        &__nums {
            &:first-child {
                margin-right: 0px;
                margin-bottom: 30px;
            }
        }

        &__free {
            margin-right: 10px;
        }

        &__nums:nth-child(1) &__free {
            margin-right: 46px;
        }

        &__cols {
            column-gap: 20px;
        }

        &__edu,
        &__skills,
        &__subjects,
        &__projects,
        &__work,
        &__profession {
            width: 300px;
        }
    }
}

.allfaculty-card {
    margin-bottom: 100px;

    &:last-child {
        margin-bottom: 0;
    }

    h3 {
        @extend .h3;
        margin-top: 100px;
        margin-bottom: 25px;

        &:first-child {
            margin-top: 0;
        }

        & > a {
            @extend .common-link;
        }
    }

    p {
        @extend .default-text;
        margin-bottom: 100px;
    }

    &__img {
        width: 100%;
        height: 580px;
        background-color: var(--scroll);
    }
}

.memory-card {
    margin-bottom: 100px;

    h3 {
        @extend .page__subtitle;
    }

    p {
        @extend .page__text;
    }

    &__img {
        width: 572px;
    }
}

@media (max-width: $second-bp) {
    .memory-card {

        picture {
            width: 460px;
        }

        .grid {
            &__col-right {
                width: 460px;
                margin-left: 20px;
            }
        }
    }
}

@media (max-width: $third-bp) {

    .memory-card {
        .grid {
            &__col-left {
                order: 2;
            }

            &__col-right {
                margin-left: 0;
                order: 1;
            }
        }

        picture {
            width: 400px;
            margin-bottom: 30px;
        }
    }
}

@media (max-width: $fourth-bp) {

    .memory-card {
        .grid {
            &__col-right {
                width: 100%;
            }
        }

        picture {
            width: 100%;
        }
    }
}

.schedule-card {
    padding: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    &:nth-child(2n - 1) {
        background-color: var(--light-background);
    }

    &__day {
        width: 120px;
        display: flex;
        flex-direction: column;

        span:first-child {
            @extend .text-nums;
        }
    }

    &__date {
        @extend .regular-text;
        margin-top: 15px;
    }

    &__row {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__time, &__type, &__name, &__teacher, &__place {
        @extend .regular-text;
    }

    &__time {
        width: 150px;
    }

    &__type {
        width: 60px;
    }

    &__name {
        width: 450px;
    }

    &__teacher {
        width: 200px;
    }
}

@media (max-width:$second-bp) {

    .schedule-card {

        &__name {
            width: 360px;
        }

        &__teacher {
            width: 150px;
        }
    }
}

@media (max-width:$third-bp) {

    .schedule-card {
        padding: 10px;

        &__day {
            width: 70px;
        }

        &__date {
            font-size: .75rem;
        }

        &__time {
            width: 110px;
        }

        &__type {
            width: 45px;
        }

        &__name {
            width: 175px;
        }

        &__teacher {
            width: 140px;
        }
    }
}

@media (max-width:$fourth-bp) {

    .schedule-card {

        &__day {
            width: 77px;
        }

        .schedule-card__group, .schedule-card__name, .schedule-card__place, .schedule-card__teacher, .schedule-card__time, .schedule-card__type {
            width: 183px;
        }

        .schedule-card__name, .schedule-card__teacher, .schedule-card__time, .schedule-card__type {
            margin-bottom: 10px;
        }

        &__time span {
            font-weight: 700;
        }

        &__row {
            flex-wrap: wrap;
        }
    }
}
