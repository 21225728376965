.abitur-header {
    margin: 50px 0;
    display: flex;
    background-color: var(--primary-background);
    overflow: hidden;

    &__items {
        padding: 50px;
        width: 640px;

        h1 {
            @extend .h1;
            margin-bottom: 50px;
            color: var(--text-light);
            word-wrap: break-word;
        }

        h3 {
            @extend .h3;
            margin-bottom: 25px;
            color: var(--text-light);
        }

        p {
            @extend .regular-text;
            margin-bottom: 20px;
            color: var(--text-light);

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    img {
        height: 560px;
    }
}

@media (max-width: $first-bp) {

    .abitur-header img {
        width: 780px;
    }
}

@media (max-width: $second-bp) {
    .abitur-header {
        &__items {
            padding: 20px;
            width: 543px;
        }

        picture, img {
            width: 417px;
        }
    }
}

@media (max-width: $third-bp) {
    .abitur-header {
        flex-direction: column;

        &__items {
            order: 2;
        }

        picture, img {
            width: 100%;
            height: 100%;
        }

        picture {
            order: 1;
        }
    }
}

@media (max-width: $fourth-bp) {

    .abitur-header {
        flex-wrap: wrap;
        margin-bottom: 0;

        &__items {
            width: 100%;
            padding: 30px 10px;
        }
    }
}

.abitur-page {
    margin-bottom: 50px;

    &__items {
        display: flex;
        align-items: flex-start;
    }

    &__content {
        width: 1320px;
    }

    &__section {
        margin-top: 100px;

        &:last-child {
            margin-bottom: 100px;
        }
    }

    h3 {
        @extend .h3;
        margin-bottom: 50px;
    }

    .select-info {
        margin-top: 100px;
    }
}

@media (max-width: $first-bp) {
    .abitur-page {

        &__content {
            width: 1000px;
        }
    }
}

@media (max-width: $second-bp) {

    .abitur-page {

        &__content {
            width: 870px;
        }
    }
}

@media (max-width: $second-bp) {

    .abitur-page .select-info {
        display: none;
    }
}

@media (max-width: $fourth-bp) {
    .abitur-page {
        &__content {
            width: 100%;
        }
    }
}

.abitur-courses {

    // &__text {
    //     @extend .regular-text;
    //     margin-bottom: 20px;
    //     width: 870px;

    //     &_caption {
    //         margin-top: 50px;
    //     }
    // }

    p {
        @extend .regular-text;
        margin-bottom: 20px;
        width: 870px;

        & > span {
            display: block;
            margin-top: 50px;
        }
    }

    a {
        @extend .common-link;
    }

    & > ul {
        @extend .common-ul-list;
        margin-bottom: 50px;
    }
}

@media (max-width: $second-bp) {

    .abitur-courses {
        p {
            width: 100%;
        }
    }
}

.how-proceed {

    &__items {
        display: flex;
        gap: 30px;
        flex-wrap: wrap;
    }
}

@media (max-width: $third-bp) {
    .how-proceed {
        &__items {
            gap: 20px;
        }
    }
}

@media (max-width: $fourth-bp) {

    .how-proceed {
        &__items {
            row-gap: 50px;
        }
    }
}

.abitur-directions {

    &__cards {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;

    }
}

@media (max-width: $third-bp) {
    .abitur-directions {
        &__cards {
            gap: 20px;
        }
    }
}

.select-info {
    position: sticky;
    width: 420px;
    height: 100%;
    padding: 0;
    top: 50px;
    margin-right: 20px;
}

@media (max-width: $first-bp) {
    .select-info {
        width: 410px;
    }
}

@media (max-width: $second-bp) {

    .select-info {
        position: relative;
        top: 0;
        width: 100%;
        margin: 0;
        margin-bottom: 50px;
    }
}

.abitur-nav {
    @extend .reset-list;
    margin: 0;
    max-width: 420px;
    width: 100%;
    height: 100%;
    padding-left: 47px;
    margin-bottom: 50px;
    max-height: calc(100dvh - 100px);
    overflow-y: auto;
    scrollbar-color: #008AD155 var(--light-background);
    scrollbar-width: thin;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        background: var(--light-background);
    }

    &::-webkit-scrollbar-thumb {
        background: #008AD155;
    }

    &_edu-direc {
        padding-left: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &__item {
        margin-bottom: 15px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__link {
        @extend .reset-link, .regular-text;
        font-weight: var(--default-text-weight);
        color: var(--buttons-links);
        position: relative;
        display: inline;
        border-bottom: 1px solid transparent;
        transition: .3s all ease-in-out;
        text-decoration: none !important;

        // &::before {
        //     content: "";
        //     position: absolute;
        //     left: 0;
        //     bottom: 0;
        //     width: 100%;
        //     height: 1px;
        //     background-color: var(--buttons-links);
        //     opacity: 0;
        //     transition: .3s all ease-in-out;
        // }

        // &:hover::before {
        //     transition: .3s all ease-in-out;
        //     opacity: 1;
        // }

        &:hover, &_active {
            border-bottom: 1px solid var(--buttons-links);
        }
    }
}

@media (max-width: $first-bp) {

    .abitur-nav {
        padding-left: 30px;
    }
}

@media (max-width: $second-bp) {

    .abitur-nav {
        max-width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 20px 100px;
        padding-left: 0;

        &__item {
            width: 345px;
            margin-bottom: 0;
        }

        &__link {
            &:hover, &_active {
                border-bottom: 1px solid transparent;
            }
        }
    }
}

@media (max-width: $third-bp) {
    .abitur-nav {
        column-gap: 20px;

        &__item {
            width: 300px;
        }
    }
}

.abitur-faq {

    p {
        @extend .regular-text;
        margin-bottom: 20px;
        width: 870px;
        padding-left: 50px;

        &:last-child {
            margin-bottom: 0;
            padding-bottom: 25px;
        }
    }

    details {
        margin-bottom: 0;
        margin-top: 0;
    }

    summary:hover {
        & > h4 {
            color: var(--text);
        }
    }

    &_faq-page {
        margin-bottom: 50px;

        p {
            padding-left: 0;
        }
    }
}

@media (max-width: $second-bp) {

    .abitur-faq {
        summary {
            width: 860px;
        }
    }
}

@media (max-width: $third-bp) {

    .abitur-faq {

        summary {
            width: 100%;
        }

        p {
            width: 100%;
            padding-left: 30px;
        }
    }
}

@media (max-width: $fourth-bp) {
    .abitur-faq {
        summary {

            width: auto;
            justify-content: space-between;

            h4 {
                width: 256px;
            }

            .small-angle {
                width: 17px;
                margin-left: 0;
            }
        }
    }
}
