.section {
    margin-bottom: 200px;

    &__title {
        margin-bottom: 100px;
    }
}

@media (max-width: $third-bp) {

    .section {
        margin-bottom: 150px;
    }
}

@media (max-width: $fourth-bp) {

    .section {
        margin-bottom: 100px;
    }
}