.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    transition: opacity .3s ease-in-out;
    opacity: 1;
    visibility: visible;

    &[aria-hidden="true"] {
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        transition: .3s all ease-in-out;
    }

    &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        background-color: rgba(0, 0, 0, .7);
    }

    &__body {
        background-color: var(--bg-color);
        padding: 75px;
        width: 1000px;
        position: relative;
    }

    &__close {
        @extend .reset-btn;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 30px;
        right: 30px;
        width: 20px;
        height: 20px;
        transition: .3s all ease-in-out;

        &:focus {
            transform: .3s all ease-in-out;
            outline: 2px solid #000;
        }
    }

    &__title {
        @extend .h3;
        margin-bottom: 25px;
    }

    &__subtitle {
        @extend .h4;
        margin-bottom: 25px;
    }

    &__text {
        @extend .regular-text;
    }

    &_small {

        .modal__body {
            width: 500px;
            padding: 30px;
        }

        .modal__close {
            top: 15px;
            right: 15px;
        }
    }
}

.icon-arrow::before {
    content: "→";
}

@keyframes dialog-appear {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@media (max-width: $third-bp) {
    .modal {
        align-items: flex-start;
        padding-top: 50px;

        &__body {
            width: 640px;
            padding: 65px 10px;
        }
    }
}

@media (max-width: $fourth-bp) {
    .modal {
        &__body {
            width: 320px;
        }
    }
}

.esd-modal {

    &__row {
        display: flex;
        margin-bottom: 30px;
    }

    &__key {
        @extend .regular-text;
        width: 135px;
        margin-right: 35px;
        flex-shrink: 0;
    }

    &__value {
        @extend .regular-text;
    }

    &__esd-open {
        @extend .regular-text, .reset-btn, .common-link;
        color: var(--buttons-links);
    
        &:focus {
            transform: .3s all ease-in-out;
            outline: 2px solid #000;
        }
    }

    &__esd-value {
        @extend .form__textarea;
        display: none;
        margin-top: 30px;
        min-height: 120px;
        margin-bottom: 0;
        resize: none;

        &_active {
            display: block;
        }
    }
}
