.btn {
    @extend .reset-link, .reset-btn;
    background-color: var(--btn-bg);
    color: var(--btn-color);
    padding: 21px 49px;
    border: 1px solid var(--btn-bg);
    font-size: var(--regular-text-small-size);
    transition: all 0.2s ease-in-out;
    text-transform: uppercase;
    font-weight: var(--default-text-weight);
    line-height: var(--btn-lh);
    letter-spacing: var(--btn-ls);

    &:hover {
        background-color: var(--hower-btn);
        border-color: var(--hower-btn);
    }

    &:active {
        transform: scale(0.98);
    }

    &:focus {
        outline: 2px solid #000;
    }

    &_light {
        background-color: var(--btn-color);
        color: var(--btn-bg);

        &:hover {
            background-color: var(--btn-bg);
            color: var(--btn-color);
            border-color: var(--btn-bg);
        }
    }

    &_transparent {
        background: 0 0;
        color: var(--btn-transparent);

        &:hover {
            background: var(--btn-bg);
            color: var(--btn-color);
            border-color: var(--btn-bg);
        }
    }

    &_active {
        color: var(--btn-color) !important;
        background-color: var(--btn-bg) !important;
    }
}

.pagination {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &__page, &__divider {
        @extend .regular-text, .reset-link;
        margin-right: 30px;
        font-weight: var(--default-text-weight);
        line-height: var(--btn-lh);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        transition: .3s all ease-in-out;

        &:last-child {
            margin-right: 0;
        }

        &_active {
            color: var(--text-hover);
        }
    }

    &__page:hover {
        color: var(--text-hover);
    }

    &__divider {
        cursor: text;
    }

    &__group {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &:hover {
            fill: var(--text-hover);
        }
    }

    &__next, &__prev {
        @extend .reset-link;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: var(--pagination-text-size);
        transition: .3s all ease-in-out;

        &:hover > svg {
            transition: .3s all ease-in-out;
            fill: var(--text-hover);
        }
    }

    &__prev {
        margin-right: 30px;
        transform: rotate(180deg);
    }

    &__next {
        margin-left: 30px;
    }
}

@media (max-width: $fourth-bp) {
    .btn {
        width: 100%;
        padding: 21px 45px;
        text-align: center;
    }

    .pagination {
        &__page,
        &__divider,
        &__prev {
            margin-right: 15px;
        }

        &__next {
            margin-left: 15px;
        }
    }
}
