@font-face {
    font-family: 'Roboto';
    src: url('../../new_assets/fonts/Roboto/Roboto-Regular.woff2') format('woff2'),
        url('../../new_assets/fonts/Roboto/Roboto-Regular.woff') format('woff'),
        url('../../new_assets/fonts/Roboto/Roboto-Regular.svg#Roboto-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../../new_assets/fonts/Roboto/Roboto-Medium.woff2') format('woff2'),
        url('../../new_assets/fonts/Roboto/Roboto-Medium.woff') format('woff'),
        url('../../new_assets/fonts/Roboto/Roboto-Medium.svg#Roboto-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../../new_assets/fonts/Roboto/Roboto-Light.woff2') format('woff2'),
        url('../../new_assets/fonts/Roboto/Roboto-Light.woff') format('woff'),
        url('../../new_assets/fonts/Roboto/Roboto-Light.svg#Roboto-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
