.cookie-block {
    position: fixed;
    bottom: 60px;
    left: 50%;
    max-width: 940px;
    width: 100%;
    border: 1px solid var(--primary-background);
    transform: translateX(-50%);
    background-color: var(--dark-blue-background);
    padding: 15px 25px;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    z-index: 9999;
    transition: .2s all ease-in-out;

    &_active {
        opacity: 1;
        pointer-events: all;
        visibility: visible;
    }

    &__group {
        display: flex;
        align-items: center;
    }

    &__text {
        @extend .regular-text;
        margin-bottom: 0;
        color: var(--text-light);

        a {
            color: var(--text-light);
            text-decoration: underline;
            transition: all .3s ease-in-out;

            &:hover {
                color: var(--text-hover);
                transition: all .3s ease-in-out;
            }
        }
    }

    &__accept {
        @extend .reset-link;
        margin-left: 50px;
        color: var(--text-light);
        padding: 12px 23px;
        text-transform: uppercase;
        border: 1px solid var(--text-light);
        transition: all .3s ease-in-out;
        text-decoration: none !important;

        &:hover {
            color: var(--text-hover);
            border-color: var(--text-hover);
            transition: all .3s ease-in-out;
        }
    }
}
