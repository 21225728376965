.footer {
    background-color: var(--dark-blue-background);
    padding: 100px 0 25px;

    &__divider {
        margin: 100px 0;
        width: 100%;
        height: 1px;
        background: var(--bg-color);
    }

    &__title {
        color: var(--text-light);
        margin-bottom: 50px;
    }

    &__contacts {
        display: flex;
    }

    &__col-contacts {
        display: flex;
        flex-direction: column;
        margin-left: 100px;
        max-width: 290px;

        &:first-child {
            margin-left: 0;
        }
    }

    &__contacts-text {
        color: var(--text-light);
        margin-bottom: 25px;

        &:last-child {
            margin-bottom: 0;
        }

        & > a {
            color: var(--text-light);
        }
    }

    &__link {
        color: var(--text-hover);
        margin-bottom: 25px;
        transition: .3s all ease-in-out;

        &:last-child {
            margin-bottom: 0;
        }

        &:hover {
            color: var(--hower-btn);
        }
    }
}

@media (max-width: $second-bp) {

    .footer {

        &__col-contacts {
            margin-left: 40px;
        }
    }
}

@media (max-width: $third-bp) {

    .footer {

        &__divider {
            position: absolute;
            width: 0;
            height: 0;
        }

        &__contacts {
            flex-direction: column;
        }

        & .grid {
            flex-direction: row;
        }

        &__col-contacts {
            margin-left: 0;
            margin-bottom: 30px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

@media (max-width: $fifth-bp) {

    .footer {

        & .grid {
            display: flex;
            flex-wrap: wrap;
        }

        & .grid__col-left, & .grid__col-rigth {
            width: 100%;
        }

        &__title {
            margin-bottom: 30px;
        }
    }
}

.footer-menu {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &__item {
        margin-right: 85px;

        &:last-child {
            margin-right: 0;
        }
    }

    &__link {
        margin-bottom: 30px;
        color: var(--text-light);
        font-weight: var(--default-text-weight);
        font-size: var(--regular-text-size);
        min-height: 40px;
        transition: .3s all ease-in-out;

        &:hover {
            color: var(--text-hover);
        }
    }
}

@media (max-width: $first-bp) {

    .footer-menu {

        &__item {
            margin-right: 67px;
        }
    }
}

@media (max-width: $second-bp) {

    .footer-menu {

        &__item {
            margin-right: 28px;
        }
    }
}

@media (max-width: $third-bp) {

    .footer-menu {
        display: none;
        position: absolute;
        height: 0;
        width: 0;
    }
}

.footer-submenu {
    display: flex;
    flex-direction: column;

    &__item {
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__link {
        margin-bottom: 30px;
        color: var(--text-light);
        font-size: var(--regular-text-size);
        transition: .3s all ease-in-out;

        &:last-child {
            margin-bottom: 0;
        }

        &:hover {
            color: var(--text-hover);
        }
    }
}

.footer-subsubmenu {
    @extend .reset-list;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    margin-left: 30px;

    &:last-child {
        margin-bottom: 0;
    }

    &__item {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__link {
        margin-bottom: 30px;
        color: var(--text-light);
        transition: .3s all ease-in-out;

        &:last-child {
            margin-bottom: 0;
        }

        &:hover {
            color: var(--text-hover);
        }
    }
}

.footer-links {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 25px 100px;
    margin-top: 86px;

    &__link {
        width: 300px;
        color: var(--text-light);
        font-size: var(--regular-text-small-size);
        line-height: var(--link-lh);
        font-weight: var(--default-text-weight);
        text-transform: uppercase;
        transition: .3s all ease-in-out;

        &:hover {
            color: var(--text-hover);
        }
    }
}

@media (max-width: $first-bp) {

    .footer-links {
        column-gap: 70px;
    }
}

@media (max-width: $second-bp) {

    .footer-links {
        display: flex;
        flex-wrap: wrap;

        &__link {
            width: 100%;
        }
    }
}

.copyright {
    display: flex;
    justify-content: space-between;
    margin-top: 120px;

    &__text {
        color: var(--text-light);
    }

    &__link {
        color: var(--text-light);
        transition: .3s all ease-in-out;

        &:hover {
            color: var(--text-hover);
        }
    }
}

@media (max-width: $fifth-bp) {

    .copyright {
        flex-direction: column;

        &__text:last-child {
            margin-top: 30px;
        }
    }
}
