.edu-direc {

    h1 {
        @extend .h1;
        margin: 50px 0;
    }

    &__main-img {
        margin-bottom: 100px;
    }

    &__group {
        display: flex;
        align-items: flex-start;
        margin-bottom: 100px;
    }

    &__content {
        width: 1320px;
    }
}

@media (max-width: $first-bp) {
    .edu-direc {
        &__content {
            width: 1000px;
        }

        .abitur-nav {
            padding-left: 0;
        }
    }
}

@media (max-width: $second-bp) {

    .edu-direc {
        &__content {
            width: 100%;
        }

        &__group {
            flex-wrap: wrap;
        }

        &__main-img {
            margin-bottom: 50px;
        }
    }
}

// @media (max-width: $third-bp) {

//     .edu-direc {
//         &__group {
//             flex-wrap: wrap;
//         }

//         &__main-img {
//             margin-bottom: 50px;
//         }

//         &__content {
//             width: 100%;
//         }
//     }
// }
