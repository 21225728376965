.content {

    h1 {
        @extend .h1;
        margin-bottom: 100px;

        &:last-child {
            margin-bottom: 0;
        }

    }

    h2 {
        @extend .h2;
        margin-bottom: 100px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    h3 {
        @extend .h3;
        margin-bottom: 50px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    h4 {
        @extend .h4;
        margin-bottom: 25px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    p {
        @extend .regular-text;
        margin-bottom: 25px;

        &:last-child {
            margin-bottom: 0;
        }

        a {
            @extend .reset-link;
            color: var(--text-hover);
            transition: .3s all ease-in-out;

            &:hover {
                color: var(--hower-btn);
                transition: .3s all ease-in-out;
            }
        }

        b {
            font-weight: bold;
        }

        strong {
            font-weight: var(--default-text-weight);
        }
    }

    ul, ol {
        @extend .regular-text;
        margin-bottom: 20px;
        padding-left: 16px;

        &:last-child {
            margin-bottom: 0;
        }

        li {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    ul {
        list-style: disc;

        li {
            margin-bottom: 0;
        }
    }

    a {
        @extend .reset-link;
        color: var(--text-hover);
        // margin-bottom: 25px;
        transition: .3s all ease-in-out;
        display: inline;

        &:last-child {
            margin-bottom: 0;
        }

        &:hover {
            color: var(--hower-btn);
        }
    }

    table {
        @extend .regular-text;
        margin-bottom: 50px;
        width: 100%;
        border: none;
        border-collapse: collapse;

        &:last-child {
            margin-bottom: 0;
        }

        & thead {
            @extend .regular-text;
            background-color: var(--dark-blue-background);
            color: var(--text-light);
        }

        tbody {
            tr:nth-child(2n - 1) {
                background: var(--light-background);
            }
        }

        & th {
            font-weight: var(--regular-text-weight);
            // padding: 16px 30px;
            padding: 15px;
            background: var(--dark-blue-background);
            color: var(--text-light);
            text-align: left;
        }

        td {
            // padding: 16px 30px;
            padding: 15px;
        }
    }

    button {
        @extend .btn;
        margin-bottom: 100px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    details {
        details {
            padding-left: 50px;

            table {

                tr {
                    vertical-align: top;
                }

                tr:nth-child(2n-1) {
                    background: var(--bg-color);
                }

                th {
                    @extend .regular-text;
                    padding-left: 0;
                    width: 630px;
                    background: var(--bg-color);
                    font-weight: var(--table-th-text-weight);
                    user-select: none;
                }
            }
        }
    }
}

@media (max-width: $second-bp) {
    .content {

        details {
            details {
                padding-left: 0;

                table {

                    tr {
                        display: grid;
                        padding: 20px 0;
                    }

                    th {
                        width: 100%;
                        padding: 0;
                    }

                    td {
                        padding: 0;
                    }
                }
            }
        }
    }
}

@media (max-width: $fourth-bp) {

    .content {

        h3 {
            margin-bottom: 30px;
        }
    }
}
