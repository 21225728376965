.nav {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 80px;
    padding: 50px 0;
    background-color: var(--dark-blue-background);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10000;

    &__group {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    svg {
        fill: var(--text-light) !important;
    }

    &__btn {
        margin-bottom: 50px;
        transition: .3s all ease-in-out;

        &:first-child {
            margin-bottom: 85px;
        }

        &:last-child {
            margin-bottom: 0;
        }

        &:hover > svg {
            transition: .3s all ease-in-out;
            fill: var(--text-hover) !important;
        }

        &:focus {
            outline: 2px solid #FFF;
            transition: .3s all ease-in-out;
        }
    }

    &__social-nav {
        margin-top: auto;
    }

    &__social-link {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 50px;
        transition: .3s all ease-in-out;

        &:hover > svg {
            transition: .3s all ease-in-out;
            fill: var(--text-hover) !important;
        }

        &:focus {
            outline: 2px solid #FFF;
            transition: .3s all ease-in-out;
        }
    }
}

.main-menu-btn, .main-menu-btn-close {
    position: relative;
    width: 30px;
    padding: 14px 0;
    margin-top: -6px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;

    & > span {
        position: relative;
        display: block;
        width: 20px;
        height: 2px;
        background-color: var(--text-light);
        transition: .3s all ease-in-out;
        pointer-events: none;
    }

    &:hover > span, &:hover > span::after, &:hover > span::before {
        background-color: var(--text-hover);
        transition: .3s all ease-in-out;
    }

    & > span::before, & > span::after {
        content: '';
        width: 100%;
        height: 100%;
        background-color: var(--text-light);
        position: absolute;
        top: 0;
        left: 0;
        transition: .3s all ease-in-out;
    }

    & > span::before {
        transform: translateY(-6px);
    }

    & > span::after {
        transform: translateY(6px);
    }

    &_active {
        & > span {
            background-color: transparent !important;
        }

        & > span::before {
            transform: rotate(45deg);
            transform-origin: center center;
        }

        & > span::after {
            transform: rotate(-45deg);
            transform-origin: center center;
        }
    }
}

.main-menu-btn-close {
    display: none;
    position: absolute;
    top: 50px;
    right: 0;
    z-index: 100000;

    &_active {
        display: flex;
    }

    span {
        position: relative;
        z-index: 100000;

        &::before, &::after {
            
            z-index: 1000000;
        }
    }
}

@media (max-width: $second-bp) {
    .nav {
        width: 45px;
        height: 45px;
        padding: 0;
        top: 25px;
        right: 20px;
        justify-content: center;
        border-radius: 50%;
        z-index: 10001;

        &_inactive {
            display: none;
        }

        &__group {
            position: absolute;
            top: 7.5px;
            right: 0;
            width: calc(100vw - 32px);
            padding: 0 7px;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;
            margin-top: 0;
        }

        &__btn {
            margin-top: 3px;
            margin-bottom: 0;

            &:first-child {
                margin: 0;
            }

            &:not(&:first-child) {
                opacity: 0;
                pointer-events: none;
                transition: none;
                visibility: hidden;
            }

            &_active {
                opacity: 1 !important;
                pointer-events: all !important;
                transition: .3s all ease-in-out !important;
                visibility: visible !important;
            }
        }

        &__MAI-logo {
            width: 100px;
        }

        &__social-nav {
            position: absolute !important;
            clip: rect(1px 1px 1px 1px);
            /* IE6, IE7 */
            clip: rect(1px, 1px, 1px, 1px);
            padding: 0 !important;
            border: 0 !important;
            height: 1px !important;
            width: 1px !important;
            overflow: hidden;
            visibility: hidden;
        }
    }

    .main-menu-btn {
        margin-top: 0;
    }
}

@media (max-width: $third-bp) {
    .nav {
        top: 20px;
        right: 10px;
    }
}

@media (max-width: $fourth-bp) {
    .nav {
        top: 10px;

        &__MAI-logo {
            width: 100px;
        }
    }
}
