.visually-hidden {
    position: absolute !important;
    clip: rect(1px 1px 1px 1px);
    /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden;
}

.reset-list {
    margin: 0;
    padding: 0;
    list-style: none;
}

.reset-btn {
    display: inline-block;
    padding: 0;
    margin: 0;
    text-decoration: none;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

.reset-link {
    display: inline-block;
    text-decoration: none;
}

.main, .header, .footer {
    margin-right: 80px;
}

.main, .header, .footer, .cookie-block, .nav {
    animation: page_appearence .9s ease-in-out;
}

@keyframes page_appearence {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@media (max-width: $second-bp) {

    .main, .header, .footer {
        margin-right: 0;
    }
}

.text-nums {
    font-size: var(--text-nums-size);
    font-weight: var(--text-nums-weight);
    line-height: var(--text-nums-lh);
}

.breadcrums {
    @extend .reset-list;
    display: flex !important;
    flex-wrap: wrap !important;

    &__link {
        @extend .reset-link;
        color: var(--buttons-links) !important;
        transition: .3s all ease-in-out !important;

        &:hover {
            color: var(--link-hover) !important;
        }

        &_active {
            color: var(--text) !important;
            pointer-events: none !important;
            text-decoration: none !important;
        }
    }

    & > li {
        padding-left: 0 !important;

        &::before {
            position: relative !important;
        }
    }

    &__divider {
        margin: 0 5px !important;
    }

    &__item, &__link, &_divider {
        @extend .regular-text;
        display: inline !important;
    }
}

.common-ul-list {
    list-style-type: disc;
    margin-bottom: 20px;
    padding-left: 25px;

    & > li {
        @extend .regular-text;
    }

    & > h4 {
        @extend .h4;
    }
}

.common-ol-list {
    list-style-type: decimal;
    padding-left: 25px;
    margin-bottom: 20px;

    & > li {
        @extend .regular-text;
    }

    & > h4 {
        @extend .h4;
    }
}

.table {
    font-size: var(--regular-text-size);
    font-weight: var(--regular-text-weight);
    line-height: var(--regular-text-lh);
    width: 100%;
    border: none;
    border-collapse: collapse;

    & tr {
        vertical-align: top;
    }

    & tr:nth-child(2n - 1) {
        background-color: var(--light-background);
    }

    & th {
        font-weight: var(--regular-text-weight);
        padding: 16px 30px;
        background: var(--dark-blue-background);
        color: var(--text-light);
        text-align: left;
    }

    & td {
        padding: 16px 30px;
        color: var(--text);
    }

    & a {
        @extend .common-link;
    }
}

.over-table {
    overflow-x: auto;
    margin-bottom: 50px;

    &:last-child {
        margin-bottom: 0;
    }

    &_medium {
        max-width: 1400px;
    }
}

.common-link {
    @extend .reset-link;
    color: var(--buttons-links);
    transition: .3s all ease-in-out;
    display: inline;

    &:hover {
        transition: .3s all ease-in-out;
        color: var(--link-hover);
    }
}

details {
    margin-top: 25px;
    margin-bottom: 25px;

    &:last-child {
        margin-bottom: 0;
    }
}

details[open] > summary {

    & span {
        transition: .3s all ease-in-out;
        transform: rotate(-180deg);
    }
}

summary {
    cursor: pointer;
    marker: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: var(--text);
    transition: .3s all ease-in-out;

    &:hover h4 {
        color: var(--text-hover);
    }

    & span.small-angle::after, & span.small-angle::before {
        width: 10px;
    }

    & span.small-angle::after {
        left: 7px;
    }

    & span.small-angle {
        width: 15px;
    }

    & span {
        width: 24px;
        height: 1px;
        display: block;
        position: relative;
        transition: .3s all ease-in-out;
        margin-left: 25px;
        flex-shrink: 0;
        margin-bottom: 25px;

        &::before, &::after {
            content: "";
            position: absolute;
            top: 0;
            width: 14px;
            height: 1px;
            background: var(--text);
            transform-origin: center center;
            transition: .3s all ease-in-out;
        }

        &::before {
            transform: rotate(45deg);
            left: 0;
        }

        &::after {
            transform: rotate(-45deg);
            left: 10px;
        }
    }

    &:hover span::after, &:hover span::before {
        background: var(--text-hover);
    }

    & h4 {
        @extend .h4;
        transition: .3s all ease-in-out;
        margin-bottom: 25px !important;
    }

    & h3 {
        @extend .h3;
        margin-bottom: 25px !important;
    }

    & p {
        text-decoration: underline;
        transition: .3s all ease-in-out;

        &:hover {
            color: var(--text-hover);
        }
    }
}

.custom-select {
    position: relative;
    background-color: var(--bg-color);
    border: 1px solid var(--input-color);
    width: 275px;
    margin-bottom: 25px;

    &_active {
        border: 1px solid var(--input-focus);
    }

    &_disabled {
        background-color: var(--disabled-background);
        border: 1px solid var(--disabled-background);
    }

    &_error {
        border: 1px solid var(--form-error);
    }

    &__open {
        @extend .regular-text;

        padding: 19px 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        white-space: nowrap;
        text-decoration: none;

        &_active .custom-select__open-arrow::before {
            transform: rotate(135deg) !important;
        }

        &_active .custom-select__open-arrow::after {
            transform: rotate(-135deg) !important;
        }

        span {
            pointer-events: none;
        }
    }

    &__open-arrow {
        display: inline-block;
        position: relative;
        width: 14px;
        height: 1px;

        &::before, &::after {
            content: "";
            position: absolute;
            top: 0;
            width: 10px;
            height: 100%;
            background-color: var(--text);
            transition: .3s all ease-in-out;
        }

        &::before {
            transform: rotate(45deg);
            left: -1px;
        }

        &::after {
            transform: rotate(-45deg);
            right: -1px;
        }
    }

    &__options {
        position: absolute;
        top: 100%;
        left: -1px;
        background-color: var(--bg-color);
        border-bottom: 1px solid var(--input-focus);
        border-left: 1px solid var(--input-focus);
        border-right: 1px solid var(--input-focus);
        opacity: 0;
        pointer-events: none;
        transition: .3s all ease-in-out;
        transform-origin: center top;
        width: calc(100% + 2px);
        max-height: 240px;
        overflow-y: auto;
        visibility: hidden;
        z-index: 1000;

        &_active {
            opacity: 1;
            pointer-events: auto;
            visibility: visible;
        }
    }

    &__option {
        @extend .regular-text;

        padding: 19px 30px;
        cursor: pointer;
        transition: .3s all ease-in-out;
        white-space: nowrap;

        &:hover {
            color: var(--text-hover);
        }
    }
}

@media (max-width:$second-bp) {

    .custom-select {
        width: 243px;
    }
}

@media (max-width:$fourth-bp) {

    .custom-select {
        width: 100%;
    }
}
