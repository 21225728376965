.main-menu {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: calc(100vw - 80px);
    z-index: 10000;
    opacity: 0;
    pointer-events: none;

    &_active {
        display: block;
        pointer-events: all;
        opacity: 1;
    }

    &__container {
        position: relative;
        display: flex;
        justify-content: space-between;
        height: auto;
        min-height: 100%;
    }

    &__left {
        opacity: 0;
        width: 32.6%;
        height: 100vh;
        background: var(--bg-color);
        padding: 50px 0 50px 50px;
        transform: scaleX(0);
        transform-origin: right center;
        transition: .2s all ease-in-out;
        visibility: hidden;
    }

    &__center {
        opacity: 0;
        display: flex;
        width: 47.8%;
        height: 100vh;
        padding: 50px 0;
        background: var(--light-background);
        overflow-y: scroll;
        transform: scaleX(0);
        transform-origin: right center;
        transition: .2s all ease-in-out;
        visibility: hidden;
        scrollbar-color: #008AD155 var(--dark-blue-background);
        scrollbar-width: 2px;
        scroll-behavior: smooth;

        &::-webkit-scrollbar {
            width: 10px;
        }

        &::-webkit-scrollbar-track {
            background: var(--dark-blue-background);
        }

        &::-webkit-scrollbar-thumb {
            background: #008AD155;
        }
    }

    &__right {
        opacity: 0;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        width: 19.6%;
        height: 100vh;
        background: var(--dark-blue-background);
        padding: 50px 50px 50px 0;
        transform: scaleX(0);
        transform-origin: right center;
        transition: .2s all ease-in-out;
        visibility: hidden;
    }

    &__left, &__center, &__right {
        &_active {
            opacity: 1;
            transform: scaleX(1);
            visibility: visible;
        }
    }
}

.submenu-back {
    @extend .reset-btn;
    display: none;
}

.next-links {
    @extend .reset-btn;
}

.menu {
    @extend .reset-list;
    margin-top: 70px;
    width: 100%;
    padding-right: 25px;

    &__item {
        @extend .default-text;
        margin-bottom: 45px;
    }

    &__link {
        @extend .common-link;
        color: var(--text);

        &:hover {
            color: var(--text-hover);
        }
    }

    &__open-submenu {
        @extend .reset-btn;

        display: none;
    }
}

.main-submenu-wrapper {
    position: relative;
    margin-top: 150px;
    margin-left: 100px;
    height: auto;
    width: 400px;

    &_small {
        margin-left: 50px;
    }
}

.main-submenu {
    @extend .reset-list;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    transition: .3s all ease-in-out;

    &__item {
        padding-bottom: 45px;
    }

    &__link {
        @extend .common-link;
        color: var(--text);

        &:hover {
            color: var(--text-hover);
        }
    }

    &_active {
        visibility: visible;
        opacity: 1;
        pointer-events: all;
    }

    &__open-submenu {
        @extend .reset-btn;

        display: none;
    }
}

.menu-info {
    margin-left: 50px;
    margin-bottom: 30px;

    &__group {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__text {
        @extend .regular-text;
        color: var(--text-light);
        line-height: 170%;

        a {
            @extend .reset-link;
            color: var(--text-light);
            display: inline-block;
        }
    }
}

@media (max-width: $second-bp) {
    .main-menu {
        width: 100vw;

        &__logo {
            display: none;
        }

        &__center, &__left {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }

        &__left {
            background-color: var(--dark-blue-background);
            padding: 120px 20px;
            transform: translateX(100%);
        }

        &__center {
            opacity: 0;
            pointer-events: none;
            transform: translateX(100%);
            z-index: 100001;
            position: relative;

            &_active {
                opacity: 1;
                pointer-events: all;
                transform: scale(1);
            }
        }

        &__right {
            display: none;
        }

        &__left {
            &_active {
                opacity: 1;
                transform: translate(0px, 0px);
            }
        }
    }

    .menu {
        margin-top: 0;
        width: 50%;

        &__item {
            margin-bottom: 50px;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
        }

        &__link {
            color: var(--text-light);
            width: 400px;
        }

        &__open-submenu {
            // display: inline-block;
            // padding: 0;
            // margin: 0;
            // text-decoration: none;
            // background-color: transparent;
            // border: none;
            // outline: none;
            // cursor: pointer;

            display: flex;
            justify-content: center;
            align-items: center;
            width: 35px;
            height: 35px;
            color: var(--text-light);
            transition: .3s all ease-in-out;
            font-size: var(--pagination-text-size);

            &:hover {
                color: var(--text-hover);
            }

            &:focus {
                transform: .3s all ease-in-out;
                outline: 2px solid #FFF;
            }

            &::before {
                content: "→";
            }
        }
    }

    .submenu-back {
        // display: inline-block;
        // padding: 0;
        // margin: 0;
        // text-decoration: none;
        // background-color: transparent;
        // border: none;
        // outline: none;
        // cursor: pointer;

        position: fixed;
        top: 25px;
        right: 20px;
        width: 45px;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: var(--pagination-text-size);
        color: var(--text);
        transform: rotate(180deg);

        &:focus {
            transform: .3s all ease-in-out;
            outline: 2px solid #000;
        }
    }

    .main-submenu-wrapper {
        margin-top: 70px;
        margin-left: 0;
        padding: 0 20px;
        width: 100%;
        display: block;
        transition: .3s all ease-in-out;
        visibility: visible;

        &_inactive {
            visibility: hidden;
            width: 0;
        }

        &_small {
            visibility: hidden;

            &_active {
                visibility: visible;
            }
        }
    }

    .main-submenu {
        width: 100%;
        padding: 0 20px;

        &__item {
            padding-bottom: 40px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &__link {
            width: 300px;
        }

        &__open-submenu {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 35px;
            height: 35px;
            color: var(--text);
            transition: .3s all ease-in-out;
            font-size: var(--pagination-text-size);

            &:hover {
                color: var(--text-hover);
            }

            &:focus {
                transform: .3s all ease-in-out;
                outline: 2px solid #000;
            }
        }
    }

    .menu-info {
        visibility: hidden;
    }
}

@media (max-width:$third-bp) {
    .menu {
        width: 100%;
        padding-right: 0;
    }

    .nav, .submenu-back {
        top: 20px;
        right: 10px;
    }
}
