.important {

    h1 {
        @extend .h1;
        padding-top: 50px;
        padding-bottom: 100px;
    }

    &__info-field {
        display: flex;
        flex-direction: column;
        margin-bottom: 100px;
    }

    h3 {
        @extend .h3;
        padding-top: 50px;
        padding-bottom: 25px;

        &:first-child {
            padding-top: 0;
        }
    }

    & a {
        @extend .reset-link;
        color: var(--text-hover);
        transition: .3s all ease-in-out;

        &:hover {
            transition: .3s all ease-in-out;
            color: var(--hower-btn);
        }

        &:hover > svg {
            transition: .3s all ease-in-out;
            fill: var(--text-hover);
        }
    }

    & p {
        @extend .regular-text;
        padding-bottom: 20px;
        width: 870px;

        &:last-child {
            padding-bottom: 0;
        }

        & > strong {
            font-weight: var(--regular-text-weight);
        }

        & > b {
            font-weight: var(--default-text-weight);
        }
    }

    &__group {
        display: flex;
        flex-direction: column;
        margin-bottom: 50px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    h2 {
        @extend .h2;
        padding: 50px 0;
    }

    &__file {
        display: flex;
        gap: 30px;
        width: 870px;
        padding-bottom: 30px;

        &:last-child {
            padding-bottom: 0;
        }
    }

    &__file-icon {
        width: 40px;
        height: 100%;
        flex-shrink: 0;
    }

    &__doc-text {
        @extend .regular-text;
        padding: 0 !important;
        margin-bottom: 15px !important;

        a {
            @extend .common-link;
        }
    }

    &__doc-buttons {
        display: flex;
        align-items: center;
        column-gap: 30px;
    }

    &__doc-link {
        @extend .reset-link, .regular-small-text;
        padding: 0 !important;
        border: none !important;
        background-color: #FFF !important;
        text-transform: none !important;
        display: flex !important;
        align-items: center !important;
        gap: 5px !important;
        color: var(--text) !important;
        transition: .3s all ease-in-out !important;
        margin: 0 !important;

        & svg {
            height: 20px;
            width: 20px;
        }

        &:focus {
            outline: none !important;
        }

        &:active {
            transform: scale(1) !important;
        }

        &:hover {
            color: var(--text-hover) !important;
            transition: .3s all ease-in-out;
        }

        &:hover > svg {
            fill: var(--text-hover);
            transition: .3s all ease-in-out;
        }
    }

    &__table-info {
        margin-bottom: 50px;
        overflow-x: auto;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__table {

        &_medium {
            width: 1400px !important;
        }

        &_small {
            width: 870px !important;
        }
    }

    table {
        @extend .regular-text;
        border-bottom: 10px solid var(--dark-blue-background);
        width: 100%;
        border-collapse: collapse;

        &:last-child {
            margin-bottom: 0;
        }

        & thead {
            @extend .regular-text;
            background-color: var(--dark-blue-background);
            color: var(--text-light);
        }

        & tr:nth-child(2n) {
            background-color: var(--light-background);
        }

        & th, & td {
            @extend .regular-text;
            font-weight: var(--regular-text-weight);
            padding: 15px;
            text-align: left;
            word-wrap: break-word;
        }

        & th {
            color: var(--text-light);
        }
    }
}

@media (max-width: $third-bp) {
    .important {
        p {
            width: 100%;
        }

        &__file {
            width: 100%;
        }

        h1 {
            padding-bottom: 50px;
        }
    }
}
