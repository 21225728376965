.page {
    margin-bottom: 100px;

    h1 {
        @extend .h1;
        padding-top: 50px;
        padding-bottom: 100px;
    }

    &__submain-title {
        @extend .h2;
        margin: 50px 0;
    }

    &__subtitle {
        @extend .h3;
        margin-top: 100px;
        margin-bottom: 25px;
        font-weight: var(--default-text-weight);

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__text {
        @extend .regular-text;
        margin-bottom: 20px;
    }

    &__subtext {
        @extend .default-text;
        margin-bottom: 100px;
    }

    &__list {
        @extend .reset-list;
        margin-bottom: 20px;

        & li {
            padding-left: 25px;
            position: relative;
            line-height: var(--regular-text-lh);

            &::before {
                content: "";
                position: absolute;
                top: 9.5px;
                left: 11px;
                width: 3px;
                height: 3px;
                background-color: var(--text);
                border-radius: 50%;
            }
        }
    }

    &__row {
        margin-bottom: 50px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__ol-list {
        @extend .reset-list;
        list-style: auto;
        margin-left: 30px;

        & li {
            padding-left: 5px;
            position: relative;
            line-height: var(--regular-text-lh);
        }
    }
}

@media (max-width: $first-bp) {
    .page .container__grid .page__text {
        width: 100%;
    }
}

@media (max-width: $second-bp) {
    .page {
        
        h1 {
            padding-bottom: 50px;
        }
    }
}

.article-page {

    &__return {
        @extend .regular-text, .reset-link;
        margin-top: 50px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: var(--text-hover);
        transition: .3s all ease-in-out;

        &:hover {
            color: var(--hower-btn);
        }

        &:hover > svg {
            transition: .3s all ease-in-out;
            fill: var(--hower-btn);
        }

        & > svg {
            margin-right: 30px;
            transform: rotate(180deg);
        }
    }

    h1 {
        @extend .h1;
        padding: 50px 0;
    }

    time {
        @extend .regular-text;
        font-weight: var(--list-text-lh);
    }

    picture {
        margin-top: 50px;
        margin-bottom: 100px;
    }

    &__info {
        margin-left: 250px;

        & p {
            @extend .regular-text;
            margin-bottom: 20px;
            max-width: 870px;

            &:last-child {
                margin-bottom: 100px;
            }

            a {
                @extend .reset-link;
                display: inline !important;
                color: var(--text-hover);
            }

            b {
                font-weight: var(--bold-weight);
            }

            strong {
                font-weight: var(--default-text-weight);
            }
        }

        ul, ol {
            @extend .regular-text;
            margin-bottom: 20px;
            padding-left: 16px;

            &:last-child {
                margin-bottom: 0;
            }

            li {
                margin-bottom: 20px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        a {
            @extend .reset-link, .regular-text;
            color: var(--text-hover);
            margin-bottom: 20px;
            transition: .3s all ease-in-out;

            &:last-child {
                margin-bottom: 0;
            }

            &:hover {
                color: var(--hower-btn);
            }
        }
    }

    &__btn-group {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 0 150px 100px;
    }

    &__prev {
        margin-left: auto;
    }
}

@media (max-width: $second-bp) {

    .article-page {
        &__info {
            margin-left: 0;
        }

        &__btn-group {
          margin: 0;
          margin-bottom: 100px;
        }
    }
}

@media (max-width: $fourth-bp) {

    .article-page {

        &__title {
            padding-bottom: 25px;
        }

        &__date {
            margin-bottom: 25px;
        }

        &__main-img {
            margin-bottom: 50px;
        }

        &__prev,
        &__next {
            padding: 22px 9px;
            width: auto;
        }

        &__info {

            img, picture {
                margin-bottom: 50px;
            }

            p, a, ul, ol {
                &:last-child {
                padding-bottom: 50px;
                }
            }
        }
    }
}

.faculty-page {

    &__subimg {
        width: 400px;
    }

    &__img {
        display: block;
        margin-bottom: 100px;
    }

    &__row {
        margin-bottom: 100px;

        h3 {
            @extend .h3;
            margin-top: 100px;
            margin-bottom: 25px;
            font-weight: var(--default-text-weight);

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        p {
            @extend .regular-text;
            margin-bottom: 20px;

            & > strong {
                @extend .text_bold;
            }
        }

        a {
            @extend .common-link;
        }

        ul {
            @extend .page__list;
        }
    }
}

@media (max-width: $third-bp) {

    .faculty-page {
        &__img {
            margin-top: 50px;
        }
    }
}

@media (max-width: $fourth-bp) {

    .faculty-page {
        &__subimg {
            width: 100%;
        }
    }
}

.library {
    h3 { @extend .page__subtitle; }
    p { @extend .page__text; }
    ul {
        @extend .page__list;
        
        li {
            @extend .regular-text;
        }
    }
    a { @extend .common-link; }
    ol {
        @extend .page__ol-list; 
        
        li {
            @extend .regular-text;
        }
    }
}

.contacts {
    margin-bottom: 100px;

    &__map {
        height: 580px;
    }

    .grid__col-left {
        & > h3 {
            @extend .page__subtitle;
        }

        & > p {
            @extend .page__text;

            & > a {
                @extend .common-link;
            }
        }

        & > a {
            @extend .common-link;
        }
    }
}

.contact {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 550px;

    &__group {
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0;
        }

        h4 {
            @extend .h4;
        }

        a {
            @extend .common-link;
        }
    }

    &__dropdown-list {
        margin-bottom: 50px;
        margin-top: 100px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__links-group {
        display: flex;
    }
}

@media (max-width: $first-bp) {
    .page {
        &__text {
            width: 640px;
        }
    }

    .contacts-page {
        &__map {
            height: 540px;
        }
    }
}

@media (max-width: $second-bp) {
    .page {
        &__title {
            padding-bottom: 50px;
        }

        &__text {
            width: 480px;
        }
    }

    .contacts {

        &__map {
            height: 400px;
        }

        .page__subtitle {
            margin-top: 50px;
        }
    }
}

@media (max-width: $third-bp) {
    .page {

        &__subtitle {
            margin-top: 50px;
        }

        &__text {
            width: 100%;
        }
    }

    .contacts {
        &__map {
            height: 350px;
        }
    }
}

@media (max-width: $fourth-bp) {
    .contacts {
        &__map {
            height: 300px;
        }

        .page__subtitle {
            margin-top: 30px;
        }

        .dropdown-list__link {
            justify-content: space-between;

            h3 {
                width: 250px;
            }
        }
    }

    .contact {
        flex-wrap: wrap;
        width: 100%;

        &__title {
            width: 100%;
            margin-bottom: 10px;
        }

        &__text {
            width: 100%;
        }
    }
}

.cources-page {
    .grid__col-left {
        p {
            @extend .page__text;
        }

        a {
            @extend .common-link;
        }

        h3 {
            @extend .page__subtitle;
        }

        ul {
            @extend .page__list;
        }
    }
}

@media (max-width: $third-bp) {

    .cources-page {

        p {
            width: 100%;
        }

        .grid {
            flex-direction: column;

            &__col-left {
                order: 2;
            }

            &__col-right {
                order: 1;
                margin-bottom: 50px;
            }
        }
    }
}

.ovz-room-page {
    .grid__col-left {
        p {
            @extend .page__text;
        }

        h3 {
            @extend .page__subtitle;
        }

        a {
            @extend .common-link;
        }

        ul {
            @extend .page__list;

            li {
                @extend .regular-text;
            }
        }
    }
}

.ovz-slider {
    position: relative;
    overflow: hidden;

    &__slide {
        pointer-events: none;
        position: absolute;
        visibility: hidden;
        opacity: 0;
        transition: .5s all ease-in-out;

        &_active {
            pointer-events: all;
            position: relative;
            visibility: visible;
            opacity: 1;
        }
    }

    &__text {
        @extend .regular-text;
        margin-top: 20px;
    }

    &__pagination {
        display: flex;
        align-items: center;
        margin-top: 30px;

        p {
            @extend .regular-text;
            font-weight: 600;
        }
    }

    &__prev-btn, &__next-btn {
        @extend .reset-btn;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            span::before, span::after {
                background-color: var(--text-hover);
            }
        }
        
        & span {
            width: 100%;
            height: 2px;
            position: relative;

            &::before, &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 14px;
                height: 100%;
                background-color: var(--text);
                transition: .3s all ease-in-out;
            }

            &::before {
                transform: rotate(45deg);
            }

            &::after {
                transform: rotate(-45deg);
            }
        }
    }

    &__prev-btn span::after, &__prev-btn span::before {
        transform-origin: left center;
    }

    &__next-btn span::after, &__next-btn span::before {
        transform-origin: right center;
        left: 9px;
    }
}

@media (max-width: $third-bp) {
    .ovz-room-page {
        .grid {

            &__col-left {
                order: 2;
            }

            &__col-right {
                order: 1;
                margin-bottom: 30px;
            }
        }
    }
}

.metod-compl-page {

    & .table {
        width: 1133px;

        thead th {
            padding-bottom: 50px;
        }

        tr td:nth-child(2) {
            display: flex;
            flex-direction: column;
            gap: 20px;
            width: 560px;
        }

        tr td:nth-child(1) {
            width: 230px;
        }

        tr td:nth-child(3) {
            width: 400px;
        }
    }

    .container {
        & > h3 {
            @extend .light-text;
            width: 870px;
            margin-bottom: 40px;
        }
    }
}

@media (max-width: $second-bp) {
    .metod-compl-page {

        &__table {
            overflow-x: auto;
        }
    }
}

@media (max-width: $third-bp) {
    .metod-compl-page {

        .container {
            & > h3 {
                width: 100%;
            }
        }
    }
}

.kolachev-page {

    &__img {
        width: 530px;
    }

    h1 {
        padding-bottom: 0;
    }

    .grid__col-left {
        p {
            @extend .page__text;

            &:last-child {
                margin-top: 85px;
            }
        }

        h2 {
            @extend .page__submain-title;
        }

        a {
            @extend .common-link;
        }
    }
}

@media (max-width: $second-bp) {
    .kolachev-page {
        .grid {
            flex-wrap: nowrap;

            &__col-left {
                width: 480px;
            }

            &__col-right {
                width: 460px;
                margin-left: 20px;
            }
        }

        &__img {
            width: 460px;
        }
    }
}

@media (max-width: $third-bp) {
    .kolachev-page {
        .grid {
            flex-wrap: wrap;

            &__col-left {
                width: 100%;
                order: 2;
            }

            &__col-right {
                width: 100%;
                margin-left: 0;
                order: 1;
            }
        }

        &__img {
            max-width: 400px;
            width: 100%;
            margin-top: 50px;
        }
    }
}

.history-page {

    &__main-img {
        width: 100%;
        height: 921px;
        background-color: var(--scroll);
        margin-bottom: 100px;
    }

    &__picture-small img {
        width: auto;
    }

    .container__grid {
        p {
            @extend .page__text;
        }

        a {
            @extend .common-link;
        }

        picture {
            margin: 100px 0;
        }
    }
}

@media(max-width: $first-bp) {

    .history-page {
        .container__grid {
            p {
                width: 100%;
            }
        }
    }
}

@media (max-width: $second-bp) {

    .history-page {

        .container__grid {
            margin: 0 auto;

            p {
                width: 100%;
            }
        }
    }
}

@media (max-width: $fourth-bp) {

    .history-page {
        &__picture-small img {
            width: 100%;
        }
    }
}

.pavlov-memory-page {

    &__video {
        width: 100%;
        height: 519.91px;
        background-color: var(--scroll);
        margin-bottom: 50px;

        video {
            width: 100%;
        }
    }

    .container__grid {
        p {
            @extend .page__text;
        }
    }
}

@media (max-width: $third-bp) {
    .pavlov-memory-page {
        &__video {
            height: auto;
        }
    }
}

.experiment-resource-page {
    // &__img {
    //     margin-top: 50px;
    //     margin-bottom: 100px;
    // }

    picture {
        margin-top: 50px;
        margin-bottom: 100px;
    }

    .container__grid {
        p {
            @extend .page__text;
        }
    }
}

@media (max-width: $first-bp) {
    .experiment-resource-page {
        .container__grid {
            p {
                width: 100%;
            }
        }
    }
}

.memory-page {
    h1+p {
        @extend .page__subtext;
        width: 1120px;
    }
}

@media (max-width: $first-bp) {
    .memory-page {
        h1 {
            padding-bottom: 50px;
        }
    }
}

@media (max-width: $second-bp) {
    .memory-page {
        h1 {
            padding-bottom: 50px;
        }

        h1+p {
            width: 100%;
        }
    }
}

.schedule-page {

    &__link {
        @extend .common-link;
    }

    &__desc {
        margin-top: 100px;
        margin-bottom: 50px;
        border-bottom: 10px solid var(--dark-blue-background);
        opacity: 0;
        height: 0;
        visibility: hidden;

        &_active {
            opacity: 1;
            height: auto;
            visibility: visible;
        }
    }

    &__info {
        @extend .regular-text;
        opacity: 0;
        margin-bottom: 150px;

        &_active {
            opacity: 1;
        }
    }

    &__group {
        margin-bottom: 50px;
        display: flex;
        align-items: flex-end;
    }

    &__week {
        @extend .default-text;

        span {
            @extend .text-nums;
        }
    }

    &__teacher {
        @extend .common-link;
        margin-left: 100px;
        margin-bottom: 3px; 
    }
}

@media (max-width: $third-bp) {

    .schedule-page {

        &__week {
            width: 100%;
        }

        &__group {
            flex-wrap: wrap;
        }

        &__teacher {
            margin-left: 0;
            margin-top: 25px;
            width: 100%;
        }

        &__desc {
            margin-top: 15px;
        }
    }
}

.search-result-page {

    .search-form {
        margin: 0;
        margin-bottom: 50px;

        &__group {
            width: 500px;
            border: 1px solid var(--text);
        }

        &__input {
            color: var(--text);
        }
        
        &__submit-btn svg {
            fill: var(--text);
        }
    }

    &__searched {
        @extend .default-text;
        margin-bottom: 20px;
    }
}

@media (max-width: $fourth-bp) {

    .search-result-page {

        .search-form__group {
            width: 100%;
        }
    
    }
}

.filter-btns {
    margin-top: 25px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;

    &__btn {
        @extend .btn, .btn_transparent;
    }
}

.search-result {
    margin-top: 50px;
    max-height: 1129px;
}

.search-result-block {
    opacity: 0;
    pointer-events: none;
    height: 0;
    visibility: hidden;
    
    &_active {
        opacity: 1;
        pointer-events: auto;
        height: auto;
        visibility: visible;
    }

    &__item {
        width: 870px;
        margin-bottom: 30px;
    }

    &__link {
        @extend .common-link, .default-text;
        color: var(--text-hover);
    }
    
    &__desc {
        @extend .regular-text;
        margin-top: 10px;
    }
}

@media (max-width: $third-bp) {

    .search-result-block {

        &__item {
            width: 100%;
        }
    }
}