.header {
    position: relative;
    z-index: 100;
    padding-top: 25px;
    margin-bottom: 50px;

    &__menu {
        display: flex;
        gap: 75px;
    }
}

@media (max-width: $first-bp) {
    .header {
        margin-bottom: 40px;
    }
}

@media (max-width: $second-bp) {
    .header {
        padding-top: 25px;
        z-index: 100;

        &__menu {
            display: none;
            position: absolute;
        }
    }
}

@media (max-width: $third-bp) {
    .header {
        padding-top: 20px;
    }
}

.menu-index {

    &__link {
        font-weight: var(--default-text-weight);
        transition: .3s all ease-in-out;

        &:hover {
            color: var(--text-hover);
            transition: .3s all ease-in-out;
        }
    }

    &__item {
        position: relative;
        transition: .3s all ease-in-out;

        &:hover .submenu, &:focus-visible .submenu, .menu-index__link:focus + .submenu {
            transition: .3s all ease-in-out;
            opacity: 1;
            pointer-events: auto;
        }

        &:focus-within .submenu {
            opacity: 1;
            pointer-events: auto;
        }
    }
}

.submenu {
    display: block;
    position: absolute;
    margin-left: -15px;
    padding-top: 20px;
    width: 215px;
    opacity: 0;
    transition: .3s all ease-in-out;
    pointer-events: none;

    &__item {
        background: var(--light-background);
        cursor: pointer;
    }

    &__link {
        padding: 12.5px 15px;
        transition: .3s all ease-in-out;

        &:hover {
            color: var(--buttons-links);
        }
    }
}
