@charset "UTF-8";

@font-face {
  font-family: "Roboto";
  src: url("../../new_assets/fonts/Roboto/Roboto-Regular.woff2") format("woff2"), url("../../new_assets/fonts/Roboto/Roboto-Regular.woff") format("woff"), url("../../new_assets/fonts/Roboto/Roboto-Regular.svg#Roboto-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../../new_assets/fonts/Roboto/Roboto-Medium.woff2") format("woff2"), url("../../new_assets/fonts/Roboto/Roboto-Medium.woff") format("woff"), url("../../new_assets/fonts/Roboto/Roboto-Medium.svg#Roboto-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../../new_assets/fonts/Roboto/Roboto-Light.woff2") format("woff2"), url("../../new_assets/fonts/Roboto/Roboto-Light.woff") format("woff"), url("../../new_assets/fonts/Roboto/Roboto-Light.svg#Roboto-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

:root {
  --font-size: 1em;
  --bg-color: #FFF;
  --light-background: #F1F8FB;
  --dark-blue-background: #111A26;
  --primary-background: #008AD1;
  --disabled-background: #EEE;
  --buttons-links: #008AD1;
  --link-hover: #115D85;
  --text: #121212;
  --text-light: #FFF;
  --text-hover: #008AD1;
  --text-disbled: #808080;
  --btn-color: #FFF;
  --btn-bg: #008AD1;
  --hower-btn: #115D85;
  --btn-transparent: #008AD1;
  --scroll: #C4C4C4;
  --important: #f21f1f;
  --input-focus: #008AD1;
  --input-color: #808080;
  --form-error: #f21f1f;
  --bold-weight: 700;
  --btn-lh: 100%;
  --btn-ls: 0.03rem;
  --link-lh: 120%;
  --pagination-text-size: 1.5rem;
  --error-text-size: .75rem;
  --text-nums-size: 2.3125rem;
  --text-nums-weight: 500;
  --text-nums-lh: 100%;
  --regular-text-size: 1rem;
  --regular-text-weight: 400;
  --regular-text-lh: 140%;
  --regular-text-small-size: .875rem;
  --default-text-weight: 500;
  --default-text-size: 1.625rem;
  --list-text-lh: 300;
  --table-td-text-size: 1.125rem;
  --table-th-text-weight: 600;
  --table-td-text-weight: 700;
  --h1-size: 4.125rem;
  --h1-weight: 500;
  --h2-size: 2.5625rem;
  --h2-weight: 500;
  --h3-size: 1.625rem;
  --h3-lh: 140%;
  --h3-weight: 500;
  --h4-size: 1rem;
  --h4-lh: 140%;
  --h4-weight: 500;
  --letter-spacing: normal;
  --main-font: "Roboto", "Arial", sans-serif;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: transparent;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type=checkbox],
[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  height: 100%;
  scroll-behavior: smooth;
  font-size: var(--font-size);
}

body {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: var(--main-font);
  background-color: var(--bg-color);
  color: var(--text);
  overflow-x: hidden;
  letter-spacing: var(--letter-spacing);
  font-size: var(--font-size);
}

picture {
  display: block;
  width: 100%;
  height: 100%;
  line-height: 0;
}

img {
  width: 100%;
  height: auto;
}

.visually-hidden {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}

.reset-list,
.priem-ul-links,
.abitur-nav,
.popular-requests,
.main-submenu,
.menu,
.page__ol-list,
.library ol,
.page__list,
.ovz-room-page .grid__col-left ul,
.cources-page .grid__col-left ul,
.library ul,
.faculty-page__row ul,
.footer-subsubmenu,
.breadcrums {
  margin: 0;
  padding: 0;
  list-style: none;
}

.reset-btn,
.first-section__sub-images,
.search-form__submit-btn,
.search__close,
.visually-impared-menu__close,
.visually-impared-menu__reset,
.visually-impared-menu__button,
.main-submenu__open-submenu,
.menu__open-submenu,
.next-links,
.submenu-back,
.esd-modal__esd-open,
.modal__close,
.ovz-slider__prev-btn,
.ovz-slider__next-btn,
.btn,
.filter-btns__btn,
.content button {
  display: inline-block;
  padding: 0;
  margin: 0;
  text-decoration: none;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.reset-link,
.priem-comp__link,
.abitur-nav__link,
.popular-requests__link,
.menu-info__text a,
.cookie-block__accept,
.article-page__info a,
.article-page__info p a,
.article-page__return,
.important__doc-link,
.important a,
.content a,
.content p a,
.abitur-proceed-card > p a,
.abitur-directions-card > p a,
.pagination__next,
.pagination__prev,
.pagination__page,
.pagination__divider,
.btn,
.filter-btns__btn,
.content button,
.text_link,
.common-link,
.priem-comp__section > h4 > a,
.priem-comp__section > p > a,
.abitur-courses a,
.main-submenu__link,
.menu__link,
.esd-modal__esd-open,
.search-result-block__link,
.schedule-page__teacher,
.schedule-page__link,
.history-page .container__grid a,
.kolachev-page .grid__col-left a,
.ovz-room-page .grid__col-left a,
.cources-page .grid__col-left a,
.contact__group a,
.contacts .grid__col-left > a,
.contacts .grid__col-left > p > a,
.library a,
.faculty-page__row a,
.important__doc-text a,
.allfaculty-card h3 > a,
.table a,
.priem-comp__table > table a,
.breadcrums__link {
  display: inline-block;
  text-decoration: none;
}

.main,
.header,
.footer {
  margin-right: 80px;
}

.main,
.header,
.footer,
.cookie-block,
.nav {
  animation: page_appearence 0.9s ease-in-out;
}

@keyframes page_appearence {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.text-nums,
.schedule-page__week span,
.schedule-card__day span:first-child {
  font-size: var(--text-nums-size);
  font-weight: var(--text-nums-weight);
  line-height: var(--text-nums-lh);
}

.breadcrums {
  display: flex !important;
  flex-wrap: wrap !important;
}

.breadcrums__link {
  color: var(--buttons-links) !important;
  transition: 0.3s all ease-in-out !important;
}

.breadcrums__link:hover {
  color: var(--link-hover) !important;
}

.breadcrums__link_active {
  color: var(--text) !important;
  pointer-events: none !important;
  text-decoration: none !important;
}

.breadcrums > li {
  padding-left: 0 !important;
}

.breadcrums > li::before {
  position: relative !important;
}

.breadcrums__divider {
  margin: 0 5px !important;
}

.breadcrums__item,
.breadcrums__link,
.breadcrums_divider {
  display: inline !important;
}

.common-ul-list,
.priem-comp__section > ul,
.abitur-courses > ul,
.direction-card__edu ul,
.direction-card__skills ul,
.direction-card__subjects ul,
.direction-card__projects ul,
.direction-card__work ul,
.direction-card__profession ul,
.direction-card__group ul,
.abitur-proceed-card > ul {
  list-style-type: disc;
  margin-bottom: 20px;
  padding-left: 25px;
}

.common-ol-list,
.direction-card__edu ol,
.direction-card__skills ol,
.direction-card__subjects ol,
.direction-card__projects ol,
.direction-card__work ol,
.direction-card__profession ol {
  list-style-type: decimal;
  padding-left: 25px;
  margin-bottom: 20px;
}

.table,
.priem-comp__table > table {
  font-size: var(--regular-text-size);
  font-weight: var(--regular-text-weight);
  line-height: var(--regular-text-lh);
  width: 100%;
  border: none;
  border-collapse: collapse;
}

.table tr,
.priem-comp__table > table tr {
  vertical-align: top;
}

.table tr:nth-child(2n-1),
.priem-comp__table > table tr:nth-child(2n-1) {
  background-color: var(--light-background);
}

.table th,
.priem-comp__table > table th {
  font-weight: var(--regular-text-weight);
  padding: 16px 30px;
  background: var(--dark-blue-background);
  color: var(--text-light);
  text-align: left;
}

.table td,
.priem-comp__table > table td {
  padding: 16px 30px;
  color: var(--text);
}

.over-table {
  overflow-x: auto;
  margin-bottom: 50px;
}

.over-table:last-child {
  margin-bottom: 0;
}

.over-table_medium {
  max-width: 1400px;
}

.common-link,
.priem-comp__section > h4 > a,
.priem-comp__section > p > a,
.abitur-courses a,
.popular-requests__link,
.main-submenu__link,
.menu__link,
.esd-modal__esd-open,
.search-result-block__link,
.schedule-page__teacher,
.schedule-page__link,
.history-page .container__grid a,
.kolachev-page .grid__col-left a,
.ovz-room-page .grid__col-left a,
.cources-page .grid__col-left a,
.contact__group a,
.contacts .grid__col-left > a,
.contacts .grid__col-left > p > a,
.library a,
.faculty-page__row a,
.important__doc-text a,
.allfaculty-card h3 > a,
.table a,
.priem-comp__table > table a {
  color: var(--buttons-links);
  transition: 0.3s all ease-in-out;
  display: inline;
}

.common-link:hover,
.priem-comp__section > h4 > a:hover,
.priem-comp__section > p > a:hover,
.abitur-courses a:hover,
.popular-requests__link:hover,
.main-submenu__link:hover,
.menu__link:hover,
.esd-modal__esd-open:hover,
.search-result-block__link:hover,
.schedule-page__teacher:hover,
.schedule-page__link:hover,
.history-page .container__grid a:hover,
.kolachev-page .grid__col-left a:hover,
.ovz-room-page .grid__col-left a:hover,
.cources-page .grid__col-left a:hover,
.contact__group a:hover,
.contacts .grid__col-left > a:hover,
.contacts .grid__col-left > p > a:hover,
.library a:hover,
.faculty-page__row a:hover,
.important__doc-text a:hover,
.allfaculty-card h3 > a:hover,
.table a:hover,
.priem-comp__table > table a:hover {
  transition: 0.3s all ease-in-out;
  color: var(--link-hover);
}

details {
  margin-top: 25px;
  margin-bottom: 25px;
}

details:last-child {
  margin-bottom: 0;
}

details[open] > summary span {
  transition: 0.3s all ease-in-out;
  transform: rotate(-180deg);
}

summary {
  cursor: pointer;
  marker: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: var(--text);
  transition: 0.3s all ease-in-out;
}

summary:hover h4 {
  color: var(--text-hover);
}

summary span.small-angle::after,
summary span.small-angle::before {
  width: 10px;
}

summary span.small-angle::after {
  left: 7px;
}

summary span.small-angle {
  width: 15px;
}

summary span {
  width: 24px;
  height: 1px;
  display: block;
  position: relative;
  transition: 0.3s all ease-in-out;
  margin-left: 25px;
  flex-shrink: 0;
  margin-bottom: 25px;
}

summary span::before,
summary span::after {
  content: "";
  position: absolute;
  top: 0;
  width: 14px;
  height: 1px;
  background: var(--text);
  transform-origin: center center;
  transition: 0.3s all ease-in-out;
}

summary span::before {
  transform: rotate(45deg);
  left: 0;
}

summary span::after {
  transform: rotate(-45deg);
  left: 10px;
}

summary:hover span::after,
summary:hover span::before {
  background: var(--text-hover);
}

summary h4 {
  transition: 0.3s all ease-in-out;
  margin-bottom: 25px !important;
}

summary h3 {
  margin-bottom: 25px !important;
}

summary p {
  text-decoration: underline;
  transition: 0.3s all ease-in-out;
}

summary p:hover {
  color: var(--text-hover);
}

.custom-select {
  position: relative;
  background-color: var(--bg-color);
  border: 1px solid var(--input-color);
  width: 275px;
  margin-bottom: 25px;
}

.custom-select_active {
  border: 1px solid var(--input-focus);
}

.custom-select_disabled {
  background-color: var(--disabled-background);
  border: 1px solid var(--disabled-background);
}

.custom-select_error {
  border: 1px solid var(--form-error);
}

.custom-select__open {
  padding: 19px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  text-decoration: none;
}

.custom-select__open_active .custom-select__open-arrow::before {
  transform: rotate(135deg) !important;
}

.custom-select__open_active .custom-select__open-arrow::after {
  transform: rotate(-135deg) !important;
}

.custom-select__open span {
  pointer-events: none;
}

.custom-select__open-arrow {
  display: inline-block;
  position: relative;
  width: 14px;
  height: 1px;
}

.custom-select__open-arrow::before,
.custom-select__open-arrow::after {
  content: "";
  position: absolute;
  top: 0;
  width: 10px;
  height: 100%;
  background-color: var(--text);
  transition: 0.3s all ease-in-out;
}

.custom-select__open-arrow::before {
  transform: rotate(45deg);
  left: -1px;
}

.custom-select__open-arrow::after {
  transform: rotate(-45deg);
  right: -1px;
}

.custom-select__options {
  position: absolute;
  top: 100%;
  left: -1px;
  background-color: var(--bg-color);
  border-bottom: 1px solid var(--input-focus);
  border-left: 1px solid var(--input-focus);
  border-right: 1px solid var(--input-focus);
  opacity: 0;
  pointer-events: none;
  transition: 0.3s all ease-in-out;
  transform-origin: center top;
  width: calc(100% + 2px);
  max-height: 240px;
  overflow-y: auto;
  visibility: hidden;
  z-index: 1000;
}

.custom-select__options_active {
  opacity: 1;
  pointer-events: auto;
  visibility: visible;
}

.custom-select__option {
  padding: 19px 30px;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  white-space: nowrap;
}

.custom-select__option:hover {
  color: var(--text-hover);
}

.h1,
.edu-direc h1,
.priem-comp h1,
.abitur-header__items h1,
.page-not-found__title,
.first-section h1,
.article-page h1,
.page h1,
.important h1,
.content h1 {
  margin: 0;
  font-family: var(--main-font);
  font-size: var(--h1-size);
  color: var(--text);
  font-weight: var(--h1-weight);
  line-height: 100%;
}

.h2,
.areas-study h2,
.page__submain-title,
.kolachev-page .grid__col-left h2,
.important h2,
.content h2 {
  margin: 0;
  font-family: var(--main-font);
  font-size: var(--h2-size);
  color: var(--text);
  font-weight: var(--h2-weight);
  line-height: 100%;
}

.h3,
.priem-comp__section-title h3,
.priem-comp__subtitle,
.abitur-page h3,
.abitur-header__items h3,
.search__title,
.visually-impared-menu__main-title,
.modal__title,
.faculty-page__row h3,
.page__subtitle,
.ovz-room-page .grid__col-left h3,
.cources-page .grid__col-left h3,
.contacts .grid__col-left > h3,
.library h3,
.memory-card h3,
.important h3,
.content h3,
.allfaculty-card h3,
.direction-card h3,
.places-card__col-left h3,
summary h3 {
  margin: 0;
  font-family: var(--main-font);
  font-size: var(--h3-size);
  color: var(--text);
  line-height: var(--h3-lh);
  font-weight: var(--h3-weight);
  line-height: 100%;
}

.h4,
.priem-comp__section > h4,
.visually-impared-menu__title,
.modal__subtitle,
.contact__group h4,
.content h4,
.direction-card__group h4,
.places-card__free h4,
.places-card__paid h4,
.abitur-proceed-card > h4,
.abitur-directions-card > p strong__title,
.abitur-directions-card > h4,
.common-ul-list > h4,
.priem-comp__section > ul > h4,
.abitur-courses > ul > h4,
.direction-card__edu ul > h4,
.direction-card__skills ul > h4,
.direction-card__subjects ul > h4,
.direction-card__projects ul > h4,
.direction-card__work ul > h4,
.direction-card__profession ul > h4,
.abitur-proceed-card > ul > h4,
.common-ol-list > h4,
.direction-card__edu ol > h4,
.direction-card__skills ol > h4,
.direction-card__subjects ol > h4,
.direction-card__projects ol > h4,
.direction-card__work ol > h4,
.direction-card__profession ol > h4,
summary h4 {
  margin: 0;
  font-family: var(--main-font);
  font-size: var(--h4-size);
  color: var(--text);
  line-height: var(--h4-lh);
  font-weight: var(--h4-weight);
  line-height: 100%;
}

.text {
  color: var(--text);
}

.text_important {
  color: var(--important) !important;
}

.text_bold,
.faculty-page__row p > strong {
  font-weight: var(--bold-weight);
}

.default-text,
.schedule-form__label,
.menu__item,
.search-result-block__link,
.search-result-page__searched,
.schedule-page__week,
.page__subtext,
.memory-page h1 + p,
.allfaculty-card p {
  margin: 0;
  font-family: var(--main-font);
  font-size: var(--default-text-size);
  color: var(--text);
  font-weight: var(--default-text-weight);
}

.light-text,
.page-not-found__text,
.first-section p,
.metod-compl-page .container > h3 {
  margin: 0;
  font-family: var(--main-font);
  font-size: var(--default-text-size);
  font-weight: var(--list-text-lh);
  color: var(--text);
}

.regular-text,
.priem-comp__link,
.priem-comp__text,
.priem-comp__section > p,
.abitur-faq p,
.abitur-nav__link,
.abitur-courses p,
.abitur-header__items p,
.search-form__input,
.menu-info__text,
.esd-modal__esd-open,
.esd-modal__value,
.esd-modal__key,
.modal__text,
.cookie-block__text,
.search-result-block__desc,
.schedule-page__info,
.ovz-slider__pagination p,
.ovz-slider__text,
.ovz-room-page .grid__col-left ul li,
.library ol li,
.library ul li,
.faculty-page__row p,
.article-page__info a,
.article-page__info ul,
.article-page__info ol,
.article-page__info p,
.article-page time,
.article-page__return,
.page__text,
.experiment-resource-page .container__grid p,
.pavlov-memory-page .container__grid p,
.history-page .container__grid p,
.kolachev-page .grid__col-left p,
.ovz-room-page .grid__col-left p,
.cources-page .grid__col-left p,
.contacts .grid__col-left > p,
.library p,
.memory-card p,
.important table th,
.important table td,
.important table thead,
.important table,
.important__doc-text,
.important p,
.content details details table th,
.content table thead,
.content table,
.content ul,
.content ol,
.content p,
.schedule-card__time,
.schedule-card__type,
.schedule-card__name,
.schedule-card__teacher,
.schedule-card__place,
.schedule-card__date,
.direction-card__edu p,
.direction-card__skills p,
.direction-card__subjects p,
.direction-card__projects p,
.direction-card__work p,
.direction-card__profession p,
.direction-card__desc p,
.direction-card__text,
.direction-card__code,
.honored-card p,
.places-card__col-left p,
.abitur-proceed-card > p a,
.abitur-proceed-card > p,
.abitur-directions-card > p strong__text,
.abitur-directions-card > p,
.abitur-directions-card > span,
.question-form__text,
.pagination__page,
.pagination__divider,
.breadcrums__item,
.breadcrums__link,
.breadcrums_divider,
.common-ul-list > li,
.priem-comp__section > ul > li,
.abitur-courses > ul > li,
.direction-card__edu ul > li,
.direction-card__skills ul > li,
.direction-card__subjects ul > li,
.direction-card__projects ul > li,
.direction-card__work ul > li,
.direction-card__profession ul > li,
.direction-card__group ul > li,
.abitur-proceed-card > ul > li,
.common-ol-list > li,
.direction-card__edu ol > li,
.direction-card__skills ol > li,
.direction-card__subjects ol > li,
.direction-card__projects ol > li,
.direction-card__work ol > li,
.direction-card__profession ol > li,
.custom-select__open,
.custom-select__option {
  margin: 0;
  font-family: var(--main-font);
  font-size: var(--regular-text-size);
  line-height: var(--regular-text-lh);
  color: var(--text);
}

.regular-small-text,
.direction-info__subtext,
.direction-info__text,
.important__doc-link,
.direction-card__count,
.direction-card__subtext {
  margin: 0;
  font-family: var(--main-font);
  font-size: var(--regular-text-small-size);
  color: var(--text);
}

.btn,
.filter-btns__btn,
.content button {
  background-color: var(--btn-bg);
  color: var(--btn-color);
  padding: 21px 49px;
  border: 1px solid var(--btn-bg);
  font-size: var(--regular-text-small-size);
  transition: all 0.2s ease-in-out;
  text-transform: uppercase;
  font-weight: var(--default-text-weight);
  line-height: var(--btn-lh);
  letter-spacing: var(--btn-ls);
}

.btn:hover,
.filter-btns__btn:hover,
.content button:hover {
  background-color: var(--hower-btn);
  border-color: var(--hower-btn);
}

.btn:active,
.filter-btns__btn:active,
.content button:active {
  transform: scale(0.98);
}

.btn:focus,
.filter-btns__btn:focus,
.content button:focus {
  outline: 2px solid #000;
}

.btn_light {
  background-color: var(--btn-color);
  color: var(--btn-bg);
}

.btn_light:hover {
  background-color: var(--btn-bg);
  color: var(--btn-color);
  border-color: var(--btn-bg);
}

.btn_transparent,
.military-department button,
.filter-btns__btn {
  background: 0 0;
  color: var(--btn-transparent);
}

.btn_transparent:hover,
.military-department button:hover,
.filter-btns__btn:hover {
  background: var(--btn-bg);
  color: var(--btn-color);
  border-color: var(--btn-bg);
}

.btn_active {
  color: var(--btn-color) !important;
  background-color: var(--btn-bg) !important;
}

.pagination {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.pagination__page,
.pagination__divider {
  margin-right: 30px;
  font-weight: var(--default-text-weight);
  line-height: var(--btn-lh);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  transition: 0.3s all ease-in-out;
}

.pagination__page:last-child,
.pagination__divider:last-child {
  margin-right: 0;
}

.pagination__page_active,
.pagination__divider_active {
  color: var(--text-hover);
}

.pagination__page:hover {
  color: var(--text-hover);
}

.pagination__divider {
  cursor: text;
}

.pagination__group {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.pagination__group:hover {
  fill: var(--text-hover);
}

.pagination__next,
.pagination__prev {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--pagination-text-size);
  transition: 0.3s all ease-in-out;
}

.pagination__next:hover > svg,
.pagination__prev:hover > svg {
  transition: 0.3s all ease-in-out;
  fill: var(--text-hover);
}

.pagination__prev {
  margin-right: 30px;
  transform: rotate(180deg);
}

.pagination__next {
  margin-left: 30px;
}

.form {
  margin-top: 50px;
}

.form__group {
  position: relative;
}

.form__input {
  font-size: var(--regular-text-size);
  padding: 21px 30px;
  border: 1px solid var(--input-color);
  width: 100%;
  margin-bottom: 25px;
  color: var(--text);
  outline: none;
}

.form__input_error {
  border: 1px solid var(--form-error);
}

.form__textarea,
.esd-modal__esd-value {
  font-size: var(--regular-text-size);
  padding: 19px 30px;
  border: 1px solid var(--input-color);
  width: 100%;
  margin-bottom: 25px;
  min-height: 230px;
  color: var(--text);
  outline: none;
}

.form__input:focus,
.form__textarea:focus,
.esd-modal__esd-value:focus {
  border: 1px solid var(--buttons-links);
}

.form__submit {
  width: 100% !important;
}

.form__info {
  margin-top: 25px;
}

.form__text-error {
  position: absolute;
  bottom: 9px;
  left: 0;
  color: var(--form-error);
  margin-left: 10px;
  font-size: var(--error-text-size);
}

.question-form__row {
  display: flex;
  justify-content: space-between;
}

.question-form__col {
  width: 400px;
}

.question-form__textarea {
  min-height: 235px;
}

.question-form__btn {
  margin-top: 25px;
}

.news-card {
  display: flex;
  flex-direction: column;
  width: 420px;
}

.news-card__img {
  display: flex;
  margin-bottom: 30px;
  width: 100%;
  height: 235px;
  justify-content: center;
  overflow: hidden;
  order: 0;
}

.news-card__date {
  margin-bottom: 12px;
  cursor: auto;
  order: 1;
}

.news-card__title {
  margin: 0;
  margin-bottom: 30px;
  transition: 0.3s all ease-in-out;
  order: 2;
}

.news-card__title:hover a {
  transition: 0.3s all ease-in-out;
  color: var(--text-hover);
}

.news-card__info {
  cursor: auto;
  order: 3;
}

.abitur-directions-card {
  width: 420px;
  padding: 20px 30px;
  border: 1px solid var(--primary-background);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

.abitur-directions-card > span {
  margin-bottom: 10px;
}

.abitur-directions-card > h4 {
  min-height: 44px;
  margin-bottom: 8px;
}

.abitur-directions-card > p {
  margin-bottom: 10px;
}

.abitur-directions-card > p:last-child {
  margin-bottom: 0;
}

.abitur-directions-card > p strong__title {
  margin-bottom: 25px;
}

.abitur-directions-card > p span {
  font-weight: var(--default-text-weight);
}

.abitur-directions-card > p a {
  color: var(--text-hover);
  transition: 0.3s all ease-in-out;
}

.abitur-directions-card > p a:hover {
  color: var(--hower-btn);
}

.abitur-proceed-card {
  width: 420px;
}

.abitur-proceed-card > h4 {
  margin-bottom: 25px;
}

.abitur-proceed-card > p {
  margin-bottom: 20px;
}

.abitur-proceed-card > p a {
  display: inline;
  color: var(--text-hover);
  transition: 0.3s all ease-in-out;
}

.abitur-proceed-card > p a:hover {
  color: var(--hower-btn);
}

.places-card {
  display: flex;
  width: 100%;
  padding: 30px;
  margin-bottom: 30px;
  border: 1px solid var(--primary-background);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

.places-card:last-child {
  margin-bottom: 0;
}

.places-card__col-left {
  max-width: 318px;
  width: 100%;
  margin-right: 50px;
  height: auto;
}

.places-card__group {
  display: flex;
  flex-direction: column;
  gap: 40px 0;
}

.places-card__free h4,
.places-card__paid h4 {
  margin-bottom: 10px;
}

.places-card__free table,
.places-card__paid table {
  border-bottom: 1px solid var(--scroll);
  border-collapse: collapse;
}

.places-card__free table td,
.places-card__paid table td {
  padding: 10px 10px 7px;
  vertical-align: top;
  text-align-last: left;
}

.places-card__free thead tr:first-child,
.places-card__paid thead tr:first-child {
  background-color: var(--light-background);
}

.places-card__free thead tr:first-child td,
.places-card__paid thead tr:first-child td {
  font-size: var(--regular-text-size);
}

.places-card__free thead td,
.places-card__paid thead td {
  padding: 10px 10px 7px;
  vertical-align: top;
  text-align-last: left;
  font-size: var(--regular-text-small-size);
}

.places-card__free tbody td,
.places-card__paid tbody td {
  font-size: var(--table-td-text-size);
  font-weight: var(--table-td-text-weight);
}

.honored-card {
  margin-bottom: 100px;
}

.honored-card:last-child {
  margin-bottom: 0;
}

.honored-card__img {
  width: 572px;
}

.honored-card p {
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.honored-card p:last-child {
  padding-bottom: 100px;
}

.direction-card {
  margin-bottom: 100px;
}

.direction-card:last-child {
  margin-bottom: 0;
}

.direction-card__header {
  display: flex;
  margin-bottom: 70px;
}

.direction-card__info {
  width: 900px;
  padding: 30px;
  background-color: var(--light-background);
}

.direction-card__code {
  display: inline-block;
  margin-bottom: 25px;
}

.direction-card__row {
  display: flex;
  margin-bottom: 70px;
}

.direction-card__row:last-child {
  margin-bottom: 0;
}

.direction-card__group {
  width: 420px;
}

.direction-card__group h4 {
  margin-bottom: 25px;
}

.direction-card__text {
  margin-bottom: 10px;
}

.direction-card__text span {
  font-weight: var(--default-text-weight);
}

.direction-card__nums {
  display: flex;
  width: 420px;
}

.direction-card__free {
  margin-right: 65px;
}

.direction-card__count {
  margin-bottom: 20px;
}

.direction-card__img {
  width: 420px;
}

.direction-card__desc {
  width: 870px;
  margin-bottom: 50px;
}

.direction-card__desc p {
  margin-bottom: 20px;
}

.direction-card__desc p:last-child {
  margin-bottom: 0;
}

.direction-card__cols {
  display: flex;
  flex-wrap: wrap;
  gap: 50px 30px;
}

.direction-card h3 {
  margin-bottom: 25px;
}

.direction-card:nth-child(1) h3 {
  margin-bottom: 10px;
}

.direction-card__edu,
.direction-card__skills,
.direction-card__subjects,
.direction-card__projects,
.direction-card__work,
.direction-card__profession {
  width: 420px;
}

.allfaculty-card {
  margin-bottom: 100px;
}

.allfaculty-card:last-child {
  margin-bottom: 0;
}

.allfaculty-card h3 {
  margin-top: 100px;
  margin-bottom: 25px;
}

.allfaculty-card h3:first-child {
  margin-top: 0;
}

.allfaculty-card p {
  margin-bottom: 100px;
}

.allfaculty-card__img {
  width: 100%;
  height: 580px;
  background-color: var(--scroll);
}

.memory-card {
  margin-bottom: 100px;
}

.memory-card__img {
  width: 572px;
}

.schedule-card {
  padding: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.schedule-card:nth-child(2n-1) {
  background-color: var(--light-background);
}

.schedule-card__day {
  width: 120px;
  display: flex;
  flex-direction: column;
}

.schedule-card__date {
  margin-top: 15px;
}

.schedule-card__row {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 30px;
}

.schedule-card__row:last-child {
  margin-bottom: 0;
}

.schedule-card__time {
  width: 150px;
}

.schedule-card__type {
  width: 60px;
}

.schedule-card__name {
  width: 450px;
}

.schedule-card__teacher {
  width: 200px;
}

.content h1 {
  margin-bottom: 100px;
}

.content h1:last-child {
  margin-bottom: 0;
}

.content h2 {
  margin-bottom: 100px;
}

.content h2:last-child {
  margin-bottom: 0;
}

.content h3 {
  margin-bottom: 50px;
}

.content h3:last-child {
  margin-bottom: 0;
}

.content h4 {
  margin-bottom: 25px;
}

.content h4:last-child {
  margin-bottom: 0;
}

.content p {
  margin-bottom: 25px;
}

.content p:last-child {
  margin-bottom: 0;
}

.content p a {
  color: var(--text-hover);
  transition: 0.3s all ease-in-out;
}

.content p a:hover {
  color: var(--hower-btn);
  transition: 0.3s all ease-in-out;
}

.content p b {
  font-weight: bold;
}

.content p strong {
  font-weight: var(--default-text-weight);
}

.content ul,
.content ol {
  margin-bottom: 20px;
  padding-left: 16px;
}

.content ul:last-child,
.content ol:last-child {
  margin-bottom: 0;
}

.content ul li,
.content ol li {
  margin-bottom: 20px;
}

.content ul li:last-child,
.content ol li:last-child {
  margin-bottom: 0;
}

.content ul {
  list-style: disc;
}

.content ul li {
  margin-bottom: 0;
}

.content a {
  color: var(--text-hover);
  transition: 0.3s all ease-in-out;
  display: inline;
}

.content a:last-child {
  margin-bottom: 0;
}

.content a:hover {
  color: var(--hower-btn);
}

.content table {
  margin-bottom: 50px;
  width: 100%;
  border: none;
  border-collapse: collapse;
}

.content table:last-child {
  margin-bottom: 0;
}

.content table thead {
  background-color: var(--dark-blue-background);
  color: var(--text-light);
}

.content table tbody tr:nth-child(2n-1) {
  background: var(--light-background);
}

.content table th {
  font-weight: var(--regular-text-weight);
  padding: 15px;
  background: var(--dark-blue-background);
  color: var(--text-light);
  text-align: left;
}

.content table td {
  padding: 15px;
}

.content button {
  margin-bottom: 100px;
}

.content button:last-child {
  margin-bottom: 0;
}

.content details details {
  padding-left: 50px;
}

.content details details table tr {
  vertical-align: top;
}

.content details details table tr:nth-child(2n-1) {
  background: var(--bg-color);
}

.content details details table th {
  padding-left: 0;
  width: 630px;
  background: var(--bg-color);
  font-weight: var(--table-th-text-weight);
  user-select: none;
}

.important h1 {
  padding-top: 50px;
  padding-bottom: 100px;
}

.important__info-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
}

.important h3 {
  padding-top: 50px;
  padding-bottom: 25px;
}

.important h3:first-child {
  padding-top: 0;
}

.important a {
  color: var(--text-hover);
  transition: 0.3s all ease-in-out;
}

.important a:hover {
  transition: 0.3s all ease-in-out;
  color: var(--hower-btn);
}

.important a:hover > svg {
  transition: 0.3s all ease-in-out;
  fill: var(--text-hover);
}

.important p {
  padding-bottom: 20px;
  width: 870px;
}

.important p:last-child {
  padding-bottom: 0;
}

.important p > strong {
  font-weight: var(--regular-text-weight);
}

.important p > b {
  font-weight: var(--default-text-weight);
}

.important__group {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
}

.important__group:last-child {
  margin-bottom: 0;
}

.important h2 {
  padding: 50px 0;
}

.important__file {
  display: flex;
  gap: 30px;
  width: 870px;
  padding-bottom: 30px;
}

.important__file:last-child {
  padding-bottom: 0;
}

.important__file-icon {
  width: 40px;
  height: 100%;
  flex-shrink: 0;
}

.important__doc-text {
  padding: 0 !important;
  margin-bottom: 15px !important;
}

.important__doc-buttons {
  display: flex;
  align-items: center;
  column-gap: 30px;
}

.important__doc-link {
  padding: 0 !important;
  border: none !important;
  background-color: #FFF !important;
  text-transform: none !important;
  display: flex !important;
  align-items: center !important;
  gap: 5px !important;
  color: var(--text) !important;
  transition: 0.3s all ease-in-out !important;
  margin: 0 !important;
}

.important__doc-link svg {
  height: 20px;
  width: 20px;
}

.important__doc-link:focus {
  outline: none !important;
}

.important__doc-link:active {
  transform: scale(1) !important;
}

.important__doc-link:hover {
  color: var(--text-hover) !important;
  transition: 0.3s all ease-in-out;
}

.important__doc-link:hover > svg {
  fill: var(--text-hover);
  transition: 0.3s all ease-in-out;
}

.important__table-info {
  margin-bottom: 50px;
  overflow-x: auto;
}

.important__table-info:last-child {
  margin-bottom: 0;
}

.important__table_medium {
  width: 1400px !important;
}

.important__table_small {
  width: 870px !important;
}

.important table {
  border-bottom: 10px solid var(--dark-blue-background);
  width: 100%;
  border-collapse: collapse;
}

.important table:last-child {
  margin-bottom: 0;
}

.important table thead {
  background-color: var(--dark-blue-background);
  color: var(--text-light);
}

.important table tr:nth-child(2n) {
  background-color: var(--light-background);
}

.important table th,
.important table td {
  font-weight: var(--regular-text-weight);
  padding: 15px;
  text-align: left;
  word-wrap: break-word;
}

.important table th {
  color: var(--text-light);
}

.container {
  max-width: 1780px;
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;
}

.container_block {
  display: block;
}

.container_flex {
  display: flex;
}

.container__grid {
  width: 870px;
  margin-left: 250px;
}

.nav {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 80px;
  padding: 50px 0;
  background-color: var(--dark-blue-background);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10000;
}

.nav__group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.nav svg {
  fill: var(--text-light) !important;
}

.nav__btn {
  margin-bottom: 50px;
  transition: 0.3s all ease-in-out;
}

.nav__btn:first-child {
  margin-bottom: 85px;
}

.nav__btn:last-child {
  margin-bottom: 0;
}

.nav__btn:hover > svg {
  transition: 0.3s all ease-in-out;
  fill: var(--text-hover) !important;
}

.nav__btn:focus {
  outline: 2px solid #FFF;
  transition: 0.3s all ease-in-out;
}

.nav__social-nav {
  margin-top: auto;
}

.nav__social-link {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
  transition: 0.3s all ease-in-out;
}

.nav__social-link:hover > svg {
  transition: 0.3s all ease-in-out;
  fill: var(--text-hover) !important;
}

.nav__social-link:focus {
  outline: 2px solid #FFF;
  transition: 0.3s all ease-in-out;
}

.main-menu-btn,
.main-menu-btn-close {
  position: relative;
  width: 30px;
  padding: 14px 0;
  margin-top: -6px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.main-menu-btn > span,
.main-menu-btn-close > span {
  position: relative;
  display: block;
  width: 20px;
  height: 2px;
  background-color: var(--text-light);
  transition: 0.3s all ease-in-out;
  pointer-events: none;
}

.main-menu-btn:hover > span,
.main-menu-btn:hover > span::after,
.main-menu-btn:hover > span::before,
.main-menu-btn-close:hover > span,
.main-menu-btn-close:hover > span::after,
.main-menu-btn-close:hover > span::before {
  background-color: var(--text-hover);
  transition: 0.3s all ease-in-out;
}

.main-menu-btn > span::before,
.main-menu-btn > span::after,
.main-menu-btn-close > span::before,
.main-menu-btn-close > span::after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: var(--text-light);
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.3s all ease-in-out;
}

.main-menu-btn > span::before,
.main-menu-btn-close > span::before {
  transform: translateY(-6px);
}

.main-menu-btn > span::after,
.main-menu-btn-close > span::after {
  transform: translateY(6px);
}

.main-menu-btn_active > span,
.main-menu-btn-close_active > span {
  background-color: transparent !important;
}

.main-menu-btn_active > span::before,
.main-menu-btn-close_active > span::before {
  transform: rotate(45deg);
  transform-origin: center center;
}

.main-menu-btn_active > span::after,
.main-menu-btn-close_active > span::after {
  transform: rotate(-45deg);
  transform-origin: center center;
}

.main-menu-btn-close {
  display: none;
  position: absolute;
  top: 50px;
  right: 0;
  z-index: 100000;
}

.main-menu-btn-close_active {
  display: flex;
}

.main-menu-btn-close span {
  position: relative;
  z-index: 100000;
}

.main-menu-btn-close span::before,
.main-menu-btn-close span::after {
  z-index: 1000000;
}

.section {
  margin-bottom: 200px;
}

.section__title {
  margin-bottom: 100px;
}

.header {
  position: relative;
  z-index: 100;
  padding-top: 25px;
  margin-bottom: 50px;
}

.header__menu {
  display: flex;
  gap: 75px;
}

.menu-index__link {
  font-weight: var(--default-text-weight);
  transition: 0.3s all ease-in-out;
}

.menu-index__link:hover {
  color: var(--text-hover);
  transition: 0.3s all ease-in-out;
}

.menu-index__item {
  position: relative;
  transition: 0.3s all ease-in-out;
}

.menu-index__item:hover .submenu,
.menu-index__item:focus-visible .submenu,
.menu-index__item .menu-index__link:focus + .submenu {
  transition: 0.3s all ease-in-out;
  opacity: 1;
  pointer-events: auto;
}

.menu-index__item:focus-within .submenu {
  opacity: 1;
  pointer-events: auto;
}

.submenu {
  display: block;
  position: absolute;
  margin-left: -15px;
  padding-top: 20px;
  width: 215px;
  opacity: 0;
  transition: 0.3s all ease-in-out;
  pointer-events: none;
}

.submenu__item {
  background: var(--light-background);
  cursor: pointer;
}

.submenu__link {
  padding: 12.5px 15px;
  transition: 0.3s all ease-in-out;
}

.submenu__link:hover {
  color: var(--buttons-links);
}

.footer {
  background-color: var(--dark-blue-background);
  padding: 100px 0 25px;
}

.footer__divider {
  margin: 100px 0;
  width: 100%;
  height: 1px;
  background: var(--bg-color);
}

.footer__title {
  color: var(--text-light);
  margin-bottom: 50px;
}

.footer__contacts {
  display: flex;
}

.footer__col-contacts {
  display: flex;
  flex-direction: column;
  margin-left: 100px;
  max-width: 290px;
}

.footer__col-contacts:first-child {
  margin-left: 0;
}

.footer__contacts-text {
  color: var(--text-light);
  margin-bottom: 25px;
}

.footer__contacts-text:last-child {
  margin-bottom: 0;
}

.footer__contacts-text > a {
  color: var(--text-light);
}

.footer__link {
  color: var(--text-hover);
  margin-bottom: 25px;
  transition: 0.3s all ease-in-out;
}

.footer__link:last-child {
  margin-bottom: 0;
}

.footer__link:hover {
  color: var(--hower-btn);
}

.footer-menu {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.footer-menu__item {
  margin-right: 85px;
}

.footer-menu__item:last-child {
  margin-right: 0;
}

.footer-menu__link {
  margin-bottom: 30px;
  color: var(--text-light);
  font-weight: var(--default-text-weight);
  font-size: var(--regular-text-size);
  min-height: 40px;
  transition: 0.3s all ease-in-out;
}

.footer-menu__link:hover {
  color: var(--text-hover);
}

.footer-submenu {
  display: flex;
  flex-direction: column;
}

.footer-submenu__item {
  margin-bottom: 30px;
}

.footer-submenu__item:last-child {
  margin-bottom: 0;
}

.footer-submenu__link {
  margin-bottom: 30px;
  color: var(--text-light);
  font-size: var(--regular-text-size);
  transition: 0.3s all ease-in-out;
}

.footer-submenu__link:last-child {
  margin-bottom: 0;
}

.footer-submenu__link:hover {
  color: var(--text-hover);
}

.footer-subsubmenu {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  margin-left: 30px;
}

.footer-subsubmenu:last-child {
  margin-bottom: 0;
}

.footer-subsubmenu__item {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.footer-subsubmenu__item:last-child {
  margin-bottom: 0;
}

.footer-subsubmenu__link {
  margin-bottom: 30px;
  color: var(--text-light);
  transition: 0.3s all ease-in-out;
}

.footer-subsubmenu__link:last-child {
  margin-bottom: 0;
}

.footer-subsubmenu__link:hover {
  color: var(--text-hover);
}

.footer-links {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 25px 100px;
  margin-top: 86px;
}

.footer-links__link {
  width: 300px;
  color: var(--text-light);
  font-size: var(--regular-text-small-size);
  line-height: var(--link-lh);
  font-weight: var(--default-text-weight);
  text-transform: uppercase;
  transition: 0.3s all ease-in-out;
}

.footer-links__link:hover {
  color: var(--text-hover);
}

.copyright {
  display: flex;
  justify-content: space-between;
  margin-top: 120px;
}

.copyright__text {
  color: var(--text-light);
}

.copyright__link {
  color: var(--text-light);
  transition: 0.3s all ease-in-out;
}

.copyright__link:hover {
  color: var(--text-hover);
}

.page {
  margin-bottom: 100px;
}

.page h1 {
  padding-top: 50px;
  padding-bottom: 100px;
}

.page__submain-title,
.kolachev-page .grid__col-left h2 {
  margin: 50px 0;
}

.page__subtitle,
.ovz-room-page .grid__col-left h3,
.cources-page .grid__col-left h3,
.contacts .grid__col-left > h3,
.library h3,
.memory-card h3 {
  margin-top: 100px;
  margin-bottom: 25px;
  font-weight: var(--default-text-weight);
}

.page__subtitle:first-child,
.ovz-room-page .grid__col-left h3:first-child,
.cources-page .grid__col-left h3:first-child,
.contacts .grid__col-left > h3:first-child,
.library h3:first-child,
.memory-card h3:first-child {
  margin-top: 0;
}

.page__subtitle:last-child,
.ovz-room-page .grid__col-left h3:last-child,
.cources-page .grid__col-left h3:last-child,
.contacts .grid__col-left > h3:last-child,
.library h3:last-child,
.memory-card h3:last-child {
  margin-bottom: 0;
}

.page__text,
.experiment-resource-page .container__grid p,
.pavlov-memory-page .container__grid p,
.history-page .container__grid p,
.kolachev-page .grid__col-left p,
.ovz-room-page .grid__col-left p,
.cources-page .grid__col-left p,
.contacts .grid__col-left > p,
.library p,
.memory-card p {
  margin-bottom: 20px;
}

.page__subtext,
.memory-page h1 + p {
  margin-bottom: 100px;
}

.page__list,
.ovz-room-page .grid__col-left ul,
.cources-page .grid__col-left ul,
.library ul,
.faculty-page__row ul {
  margin-bottom: 20px;
}

.page__list li,
.ovz-room-page .grid__col-left ul li,
.cources-page .grid__col-left ul li,
.library ul li,
.faculty-page__row ul li {
  padding-left: 25px;
  position: relative;
  line-height: var(--regular-text-lh);
}

.page__list li::before,
.ovz-room-page .grid__col-left ul li::before,
.cources-page .grid__col-left ul li::before,
.library ul li::before,
.faculty-page__row ul li::before {
  content: "";
  position: absolute;
  top: 9.5px;
  left: 11px;
  width: 3px;
  height: 3px;
  background-color: var(--text);
  border-radius: 50%;
}

.page__row {
  margin-bottom: 50px;
}

.page__row:last-child {
  margin-bottom: 0;
}

.page__ol-list,
.library ol {
  list-style: auto;
  margin-left: 30px;
}

.page__ol-list li,
.library ol li {
  padding-left: 5px;
  position: relative;
  line-height: var(--regular-text-lh);
}

.article-page__return {
  margin-top: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: var(--text-hover);
  transition: 0.3s all ease-in-out;
}

.article-page__return:hover {
  color: var(--hower-btn);
}

.article-page__return:hover > svg {
  transition: 0.3s all ease-in-out;
  fill: var(--hower-btn);
}

.article-page__return > svg {
  margin-right: 30px;
  transform: rotate(180deg);
}

.article-page h1 {
  padding: 50px 0;
}

.article-page time {
  font-weight: var(--list-text-lh);
}

.article-page picture {
  margin-top: 50px;
  margin-bottom: 100px;
}

.article-page__info {
  margin-left: 250px;
}

.article-page__info p {
  margin-bottom: 20px;
  max-width: 870px;
}

.article-page__info p:last-child {
  margin-bottom: 100px;
}

.article-page__info p a {
  display: inline !important;
  color: var(--text-hover);
}

.article-page__info p b {
  font-weight: var(--bold-weight);
}

.article-page__info p strong {
  font-weight: var(--default-text-weight);
}

.article-page__info ul,
.article-page__info ol {
  margin-bottom: 20px;
  padding-left: 16px;
}

.article-page__info ul:last-child,
.article-page__info ol:last-child {
  margin-bottom: 0;
}

.article-page__info ul li,
.article-page__info ol li {
  margin-bottom: 20px;
}

.article-page__info ul li:last-child,
.article-page__info ol li:last-child {
  margin-bottom: 0;
}

.article-page__info a {
  color: var(--text-hover);
  margin-bottom: 20px;
  transition: 0.3s all ease-in-out;
}

.article-page__info a:last-child {
  margin-bottom: 0;
}

.article-page__info a:hover {
  color: var(--hower-btn);
}

.article-page__btn-group {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 150px 100px;
}

.article-page__prev {
  margin-left: auto;
}

.faculty-page__subimg {
  width: 400px;
}

.faculty-page__img {
  display: block;
  margin-bottom: 100px;
}

.faculty-page__row {
  margin-bottom: 100px;
}

.faculty-page__row h3 {
  margin-top: 100px;
  margin-bottom: 25px;
  font-weight: var(--default-text-weight);
}

.faculty-page__row h3:first-child {
  margin-top: 0;
}

.faculty-page__row h3:last-child {
  margin-bottom: 0;
}

.faculty-page__row p {
  margin-bottom: 20px;
}

.contacts {
  margin-bottom: 100px;
}

.contacts__map {
  height: 580px;
}

.contact {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 550px;
}

.contact__group {
  margin-bottom: 30px;
}

.contact__group:last-child {
  margin-bottom: 0;
}

.contact__dropdown-list {
  margin-bottom: 50px;
  margin-top: 100px;
}

.contact__dropdown-list:last-child {
  margin-bottom: 0;
}

.contact__links-group {
  display: flex;
}

.ovz-slider {
  position: relative;
  overflow: hidden;
}

.ovz-slider__slide {
  pointer-events: none;
  position: absolute;
  visibility: hidden;
  opacity: 0;
  transition: 0.5s all ease-in-out;
}

.ovz-slider__slide_active {
  pointer-events: all;
  position: relative;
  visibility: visible;
  opacity: 1;
}

.ovz-slider__text {
  margin-top: 20px;
}

.ovz-slider__pagination {
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.ovz-slider__pagination p {
  font-weight: 600;
}

.ovz-slider__prev-btn,
.ovz-slider__next-btn {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ovz-slider__prev-btn:hover span::before,
.ovz-slider__prev-btn:hover span::after,
.ovz-slider__next-btn:hover span::before,
.ovz-slider__next-btn:hover span::after {
  background-color: var(--text-hover);
}

.ovz-slider__prev-btn span,
.ovz-slider__next-btn span {
  width: 100%;
  height: 2px;
  position: relative;
}

.ovz-slider__prev-btn span::before,
.ovz-slider__prev-btn span::after,
.ovz-slider__next-btn span::before,
.ovz-slider__next-btn span::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 14px;
  height: 100%;
  background-color: var(--text);
  transition: 0.3s all ease-in-out;
}

.ovz-slider__prev-btn span::before,
.ovz-slider__next-btn span::before {
  transform: rotate(45deg);
}

.ovz-slider__prev-btn span::after,
.ovz-slider__next-btn span::after {
  transform: rotate(-45deg);
}

.ovz-slider__prev-btn span::after,
.ovz-slider__prev-btn span::before {
  transform-origin: left center;
}

.ovz-slider__next-btn span::after,
.ovz-slider__next-btn span::before {
  transform-origin: right center;
  left: 9px;
}

.metod-compl-page .table,
.metod-compl-page .priem-comp__table > table {
  width: 1133px;
}

.metod-compl-page .table thead th,
.metod-compl-page .priem-comp__table > table thead th {
  padding-bottom: 50px;
}

.metod-compl-page .table tr td:nth-child(2),
.metod-compl-page .priem-comp__table > table tr td:nth-child(2) {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 560px;
}

.metod-compl-page .table tr td:nth-child(1),
.metod-compl-page .priem-comp__table > table tr td:nth-child(1) {
  width: 230px;
}

.metod-compl-page .table tr td:nth-child(3),
.metod-compl-page .priem-comp__table > table tr td:nth-child(3) {
  width: 400px;
}

.metod-compl-page .container > h3 {
  width: 870px;
  margin-bottom: 40px;
}

.kolachev-page__img {
  width: 530px;
}

.kolachev-page h1 {
  padding-bottom: 0;
}

.kolachev-page .grid__col-left p:last-child {
  margin-top: 85px;
}

.history-page__main-img {
  width: 100%;
  height: 921px;
  background-color: var(--scroll);
  margin-bottom: 100px;
}

.history-page__picture-small img {
  width: auto;
}

.history-page .container__grid picture {
  margin: 100px 0;
}

.pavlov-memory-page__video {
  width: 100%;
  height: 519.91px;
  background-color: var(--scroll);
  margin-bottom: 50px;
}

.pavlov-memory-page__video video {
  width: 100%;
}

.experiment-resource-page picture {
  margin-top: 50px;
  margin-bottom: 100px;
}

.memory-page h1 + p {
  width: 1120px;
}

.schedule-page__desc {
  margin-top: 100px;
  margin-bottom: 50px;
  border-bottom: 10px solid var(--dark-blue-background);
  opacity: 0;
  height: 0;
  visibility: hidden;
}

.schedule-page__desc_active {
  opacity: 1;
  height: auto;
  visibility: visible;
}

.schedule-page__info {
  opacity: 0;
  margin-bottom: 150px;
}

.schedule-page__info_active {
  opacity: 1;
}

.schedule-page__group {
  margin-bottom: 50px;
  display: flex;
  align-items: flex-end;
}

.schedule-page__teacher {
  margin-left: 100px;
  margin-bottom: 3px;
}

.search-result-page .search-form {
  margin: 0;
  margin-bottom: 50px;
}

.search-result-page .search-form__group {
  width: 500px;
  border: 1px solid var(--text);
}

.search-result-page .search-form__input {
  color: var(--text);
}

.search-result-page .search-form__submit-btn svg {
  fill: var(--text);
}

.search-result-page__searched {
  margin-bottom: 20px;
}

.filter-btns {
  margin-top: 25px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

.search-result {
  margin-top: 50px;
  max-height: 1129px;
}

.search-result-block {
  opacity: 0;
  pointer-events: none;
  height: 0;
  visibility: hidden;
}

.search-result-block_active {
  opacity: 1;
  pointer-events: auto;
  height: auto;
  visibility: visible;
}

.search-result-block__item {
  width: 870px;
  margin-bottom: 30px;
}

.search-result-block__link {
  color: var(--text-hover);
}

.search-result-block__desc {
  margin-top: 10px;
}

.cookie-block {
  position: fixed;
  bottom: 60px;
  left: 50%;
  max-width: 940px;
  width: 100%;
  border: 1px solid var(--primary-background);
  transform: translateX(-50%);
  background-color: var(--dark-blue-background);
  padding: 15px 25px;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  z-index: 9999;
  transition: 0.2s all ease-in-out;
}

.cookie-block_active {
  opacity: 1;
  pointer-events: all;
  visibility: visible;
}

.cookie-block__group {
  display: flex;
  align-items: center;
}

.cookie-block__text {
  margin-bottom: 0;
  color: var(--text-light);
}

.cookie-block__text a {
  color: var(--text-light);
  text-decoration: underline;
  transition: all 0.3s ease-in-out;
}

.cookie-block__text a:hover {
  color: var(--text-hover);
  transition: all 0.3s ease-in-out;
}

.cookie-block__accept {
  margin-left: 50px;
  color: var(--text-light);
  padding: 12px 23px;
  text-transform: uppercase;
  border: 1px solid var(--text-light);
  transition: all 0.3s ease-in-out;
  text-decoration: none !important;
}

.cookie-block__accept:hover {
  color: var(--text-hover);
  border-color: var(--text-hover);
  transition: all 0.3s ease-in-out;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  transition: opacity 0.3s ease-in-out;
  opacity: 1;
  visibility: visible;
}

.modal[aria-hidden=true] {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  transition: 0.3s all ease-in-out;
}

.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.7);
}

.modal__body {
  background-color: var(--bg-color);
  padding: 75px;
  width: 1000px;
  position: relative;
}

.modal__close {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 30px;
  right: 30px;
  width: 20px;
  height: 20px;
  transition: 0.3s all ease-in-out;
}

.modal__close:focus {
  transform: 0.3s all ease-in-out;
  outline: 2px solid #000;
}

.modal__title {
  margin-bottom: 25px;
}

.modal__subtitle {
  margin-bottom: 25px;
}

.modal_small .modal__body {
  width: 500px;
  padding: 30px;
}

.modal_small .modal__close {
  top: 15px;
  right: 15px;
}

.icon-arrow::before {
  content: "→";
}

@keyframes dialog-appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.esd-modal__row {
  display: flex;
  margin-bottom: 30px;
}

.esd-modal__key {
  width: 135px;
  margin-right: 35px;
  flex-shrink: 0;
}

.esd-modal__esd-open {
  color: var(--buttons-links);
}

.esd-modal__esd-open:focus {
  transform: 0.3s all ease-in-out;
  outline: 2px solid #000;
}

.esd-modal__esd-value {
  display: none;
  margin-top: 30px;
  min-height: 120px;
  margin-bottom: 0;
  resize: none;
}

.esd-modal__esd-value_active {
  display: block;
}

.main-menu {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: calc(100vw - 80px);
  z-index: 10000;
  opacity: 0;
  pointer-events: none;
}

.main-menu_active {
  display: block;
  pointer-events: all;
  opacity: 1;
}

.main-menu__container {
  position: relative;
  display: flex;
  justify-content: space-between;
  height: auto;
  min-height: 100%;
}

.main-menu__left {
  opacity: 0;
  width: 32.6%;
  height: 100vh;
  background: var(--bg-color);
  padding: 50px 0 50px 50px;
  transform: scaleX(0);
  transform-origin: right center;
  transition: 0.2s all ease-in-out;
  visibility: hidden;
}

.main-menu__center {
  opacity: 0;
  display: flex;
  width: 47.8%;
  height: 100vh;
  padding: 50px 0;
  background: var(--light-background);
  overflow-y: scroll;
  transform: scaleX(0);
  transform-origin: right center;
  transition: 0.2s all ease-in-out;
  visibility: hidden;
  scrollbar-color: rgba(0, 138, 209, 0.3333333333) var(--dark-blue-background);
  scrollbar-width: 2px;
  scroll-behavior: smooth;
}

.main-menu__center::-webkit-scrollbar {
  width: 10px;
}

.main-menu__center::-webkit-scrollbar-track {
  background: var(--dark-blue-background);
}

.main-menu__center::-webkit-scrollbar-thumb {
  background: rgba(0, 138, 209, 0.3333333333);
}

.main-menu__right {
  opacity: 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 19.6%;
  height: 100vh;
  background: var(--dark-blue-background);
  padding: 50px 50px 50px 0;
  transform: scaleX(0);
  transform-origin: right center;
  transition: 0.2s all ease-in-out;
  visibility: hidden;
}

.main-menu__left_active,
.main-menu__center_active,
.main-menu__right_active {
  opacity: 1;
  transform: scaleX(1);
  visibility: visible;
}

.submenu-back {
  display: none;
}

.menu {
  margin-top: 70px;
  width: 100%;
  padding-right: 25px;
}

.menu__item {
  margin-bottom: 45px;
}

.menu__link {
  color: var(--text);
}

.menu__link:hover {
  color: var(--text-hover);
}

.menu__open-submenu {
  display: none;
}

.main-submenu-wrapper {
  position: relative;
  margin-top: 150px;
  margin-left: 100px;
  height: auto;
  width: 400px;
}

.main-submenu-wrapper_small {
  margin-left: 50px;
}

.main-submenu {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transition: 0.3s all ease-in-out;
}

.main-submenu__item {
  padding-bottom: 45px;
}

.main-submenu__link {
  color: var(--text);
}

.main-submenu__link:hover {
  color: var(--text-hover);
}

.main-submenu_active {
  visibility: visible;
  opacity: 1;
  pointer-events: all;
}

.main-submenu__open-submenu {
  display: none;
}

.menu-info {
  margin-left: 50px;
  margin-bottom: 30px;
}

.menu-info__group {
  margin-bottom: 20px;
}

.menu-info__group:last-child {
  margin-bottom: 0;
}

.menu-info__text {
  color: var(--text-light);
  line-height: 170%;
}

.menu-info__text a {
  color: var(--text-light);
  display: inline-block;
}

.visually-impared-menu {
  padding-top: 25px;
  padding-bottom: 25px;
  margin-right: 80px;
  margin-top: -100px;
  transition: 0.3s all ease-in-out;
  background: var(--bg-color);
  visibility: hidden;
}

.visually-impared-menu_active {
  visibility: visible;
  margin-top: 0 !important;
  opacity: 1 !important;
  pointer-events: auto !important;
  transition: 0.3s all ease-in-out;
}

.visually-impared-menu__main-title,
.visually-impared-menu__title {
  display: none;
}

.visually-impared-menu__group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.visually-impared-menu__items {
  display: flex;
  justify-content: center;
  align-items: center;
}

.visually-impared-menu__button {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--light-background);
  font-weight: var(--default-text-weight);
  color: var(--text);
  border: 3px solid transparent;
  margin-right: 10px;
  font-size: 22px;
  flex-shrink: 0;
  transition: 0.3s all ease-in-out;
}

.visually-impared-menu__button:last-child {
  margin-right: 0;
}

.visually-impared-menu__button_active,
.visually-impared-menu__button:hover,
.visually-impared-menu__button:focus {
  border-color: var(--btn-transparent);
  transition: 0.3s all ease-in-out;
}

.visually-impared-menu__button svg {
  pointer-events: none;
}

.visually-impared-menu__button_text-size_normal {
  font-size: 18px;
}

.visually-impared-menu__button_text-size_medium {
  font-size: 22px;
}

.visually-impared-menu__button_text-size_big {
  font-size: 26px;
}

.visually-impared-menu__button_color_wb {
  background-color: #eee;
}

.visually-impared-menu__button_color_bw {
  background: #000;
  color: #FFF;
}

.visually-impared-menu__button_color_chrom {
  background-color: #9dd1ff;
  color: #14305e;
}

.visually-impared-menu__button_letter-spacing {
  font-size: 18px;
}

.visually-impared-menu__button_letter-spacing_normal {
  letter-spacing: normal;
}

.visually-impared-menu__button_letter-spacing_1px {
  letter-spacing: 1px;
}

.visually-impared-menu__button_letter-spacing_2px {
  letter-spacing: 2px;
}

.visually-impared-menu__button_font_sans-serif {
  font-family: Roboto !important;
}

.visually-impared-menu__button_font_serif {
  font-family: Georgia !important;
  font-weight: 700;
}

.visually-impared-menu__button_img {
  font-size: 24px;
}

.visually-impared-menu__reset {
  font-weight: var(--default-text-weight);
  color: var(--text);
  font-size: 1.625rem;
}

.visually-impared-menu__reset:focus {
  border: 3px solid transparent;
  border-color: var(--btn-transparent);
  transition: 0.3s all ease-in-out;
}

.visually-impared-menu__close {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
}

.visually-impared-menu__close span {
  position: relative;
  width: 22px;
  height: 2px;
  background-color: transparent;
}

.visually-impared-menu__close span::before,
.visually-impared-menu__close span::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--text);
  transform-origin: center center;
}

.visually-impared-menu__close span::before {
  transform: rotate(45deg);
}

.visually-impared-menu__close span::after {
  transform: rotate(-45deg);
}

.visually-impared-menu__close:focus {
  border: 3px solid transparent;
  border-color: var(--btn-transparent);
  transition: 0.3s all ease-in-out;
}

.search {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: calc(100% - 80px);
  overflow: hidden;
  visibility: hidden;
  background-color: var(--dark-blue-background);
  z-index: 10000;
  transform-origin: right center;
  pointer-events: none;
  opacity: 0;
  transition: 0.3s all ease-in-out;
}

.search_active {
  pointer-events: all;
  opacity: 1;
  visibility: visible;
  transition: 0.3s all ease-in-out;
}

.search__container {
  display: flex;
  height: auto;
  min-height: 100%;
  visibility: hidden;
  opacity: 0;
  transform: scaleX(0);
  transform-origin: right center;
  transition: 0.3s all ease-in-out;
}

.search__container_active {
  visibility: visible;
  opacity: 1;
  transform: scaleX(1);
  transition: 0.3s all ease-in-out;
}

.search__left {
  width: 600px;
  height: 100vh;
  padding: 50px;
}

.search__logo {
  width: 200px;
}

.search__right {
  display: flex;
  align-items: flex-start;
  width: 1320px;
  height: 100vh;
  padding: 50px;
}

.search__group {
  margin-top: 210px;
}

.search__title {
  color: var(--text-light);
}

.search__popular {
  margin-top: 50px;
}

.search__close {
  margin-top: 215px;
  margin-left: 100px;
  color: var(--text-light);
  font-size: 22px;
  transition: 0.3s all ease-in-out;
}

.search__close:hover {
  color: var(--link-hover);
}

.search__close:focus {
  outline: 2px solid var(--text-light);
  transition: 0.3s all ease-in-out;
}

.search-form {
  display: block;
  margin-top: 115px;
  width: 100%;
}

.search-form__group {
  display: flex;
  align-items: center;
  padding: 20px 30px;
  width: 100%;
  border: 1px solid var(--bg-color);
}

.search-form__input {
  width: 100%;
  margin-right: 30px;
  background-color: transparent;
  outline: 0;
  border: 0;
  color: var(--text-light);
}

.search-form__input::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
}

.search-form__submit-btn {
  color: var(--text-light);
  margin-left: auto;
  transition: 0.3s all ease-in-out;
}

.search-form__submit-btn:hover svg {
  fill: var(--text-hover);
  transition: 0.3s all ease-in-out;
}

.search-form__submit-btn:focus {
  outline: 2px solid var(--text-light);
  transition: 0.3s all ease-in-out;
}

.popular-requests {
  display: flex;
  flex-direction: column;
  row-gap: 25px;
}

.popular-requests__link {
  color: var(--text-light);
}

.popular-requests__link:hover {
  color: var(--text-hover);
}

.first-section {
  position: relative;
}

.first-section .grid__col-left {
  display: flex;
  flex-direction: column;
}

.first-section__slider {
  display: flex;
  overflow: auto;
  width: 100%;
  max-width: 800px;
  position: relative;
  gap: 15px;
  transition: 0.3s all ease-in-out;
  overscroll-behavior-inline: contain;
  -ms-scroll-snap-type: inline mandatory;
  scroll-snap-type: inline mandatory;
  scroll-behavior: smooth;
  scrollbar-width: none;
}

.first-section__slider img {
  height: 100%;
}

.first-section__slider div {
  scroll-snap-align: start;
}

.first-section__main-img {
  flex: 1 0 100%;
  transition: all 0.5s ease-in-out;
  max-height: 500px;
}

.first-section__sub-slider {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
  width: 100%;
  max-width: 800px;
}

.first-section__sub-images {
  width: 100%;
  cursor: pointer;
}

.first-section__sub-images img {
  border-radius: 10px;
}

.first-section__sub-images:focus {
  outline: 2px solid var(--text);
  transition: 0.3s all ease-in-out;
}

.first-section__title {
  margin-bottom: 50px;
}

.first-section h1 {
  margin-bottom: 25px;
  text-transform: uppercase;
  width: 100%;
}

.first-section__buttons {
  display: flex;
  gap: 25px;
}

.first-section__personal-accounts {
  display: flex;
  align-items: end;
  justify-content: space-between;
  margin-top: auto;
}

.first-section__account-link {
  max-width: 238px;
  width: 100%;
}

.first-section__column {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.articles__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 100px;
}

.articles__all-news {
  display: flex;
  align-items: center;
  transition: 0.3s all ease-in-out;
}

.articles__all-news:hover > span {
  color: var(--text-hover);
  transition: 0.3s all ease-in-out;
}

.articles__all-news:hover > span > svg {
  fill: var(--text-hover);
  transition: 0.3s all ease-in-out;
}

.articles__all-news-arrow {
  width: 24px;
  height: 24px;
}

.articles__all-news-text {
  margin-right: 30px;
  text-transform: lowercase;
}

.articles__view-cards {
  display: grid;
  grid-auto-flow: column;
  gap: 20px;
  padding-bottom: 10px;
  overflow-x: auto;
  overscroll-behavior-inline: contain;
  -ms-scroll-snap-type: inline mandatory;
  scroll-snap-type: inline mandatory;
  scroll-padding-inline: 20px;
  scrollbar-color: rgba(0, 138, 209, 0.3333333333) var(--light-background);
  scrollbar-width: thin;
  scroll-behavior: smooth;
}

.articles__view-cards::-webkit-scrollbar-thumb {
  height: 10px;
}

.articles__view-cards::-webkit-scrollbar-track {
  background: var(--light-background);
}

.articles__view-cards::-webkit-scrollbar-thumb {
  background: rgba(0, 138, 209, 0.3333333333);
}

.articles__view-cards article {
  scroll-snap-align: start;
}

.grid {
  display: flex;
}

.grid:last-child {
  margin-bottom: 0;
}

.grid__col-left {
  position: relative;
  width: 870px;
}

.grid__col-left_pl {
  padding-left: 130px;
}

.grid__col-right {
  position: relative;
  width: 770px;
  margin-left: 100px;
}

.areas-study h2 {
  margin-bottom: 100px;
}

.areas-study__card {
  padding-left: 130px;
}

.areas-study__img {
  position: sticky;
  top: 0;
  bottom: -50px;
}

.areas-study__direction-card {
  margin-bottom: 200px;
}

.study-card__title {
  margin-bottom: 50px;
  padding-left: 130px;
  line-height: var(--text-nums-lh);
  position: relative;
}

.study-card__id-class {
  position: absolute;
  font-size: var(--regular-text-size);
  font-weight: var(--regular-text-weight);
  bottom: -1px;
  left: 0;
}

.study-card__info {
  margin-bottom: 10px;
}

.study-card__info > strong {
  font-weight: var(--default-text-weight);
}

.study-card__link {
  color: var(--text-hover);
  transition: 0.3s all ease-in-out;
}

.study-card__link:hover {
  color: var(--hower-btn);
}

.study-card__btn {
  margin-bottom: 100px;
}

.study-card__direction-info {
  margin: 50px 0 100px 0;
  color: var(--text);
}

.direction-info {
  display: flex;
  justify-content: flex-start;
}

.direction-info__group {
  margin-bottom: 50px;
}

.direction-info__group:last-child {
  margin-bottom: 0;
}

.direction-info__text {
  margin-bottom: 20px;
}

.direction-info__paid,
.direction-info__cost {
  margin-left: 75px;
}

.military-department {
  padding: 100px 0;
}

.military-department button {
  margin-top: 50px;
}

.page-not-found {
  margin-bottom: 100px;
}

.page-not-found__img {
  width: 100%;
  margin-top: 30px;
  padding: 0 60px;
}

.page-not-found__title {
  margin-top: 130px;
  padding-bottom: 30px;
}

.page-not-found__text {
  margin-bottom: 50px;
}

.news-page__articles {
  margin-bottom: 100px;
}

.news-page__navigation {
  display: flex;
  align-items: center;
  margin-bottom: 100px;
}

.cards__items {
  display: flex;
  flex-wrap: wrap;
  gap: 100px 20px;
}

.abitur-header {
  margin: 50px 0;
  display: flex;
  background-color: var(--primary-background);
  overflow: hidden;
}

.abitur-header__items {
  padding: 50px;
  width: 640px;
}

.abitur-header__items h1 {
  margin-bottom: 50px;
  color: var(--text-light);
  word-wrap: break-word;
}

.abitur-header__items h3 {
  margin-bottom: 25px;
  color: var(--text-light);
}

.abitur-header__items p {
  margin-bottom: 20px;
  color: var(--text-light);
}

.abitur-header__items p:last-child {
  margin-bottom: 0;
}

.abitur-header img {
  height: 560px;
}

.abitur-page {
  margin-bottom: 50px;
}

.abitur-page__items {
  display: flex;
  align-items: flex-start;
}

.abitur-page__content {
  width: 1320px;
}

.abitur-page__section {
  margin-top: 100px;
}

.abitur-page__section:last-child {
  margin-bottom: 100px;
}

.abitur-page h3 {
  margin-bottom: 50px;
}

.abitur-page .select-info {
  margin-top: 100px;
}

.abitur-courses p {
  margin-bottom: 20px;
  width: 870px;
}

.abitur-courses p > span {
  display: block;
  margin-top: 50px;
}

.abitur-courses > ul {
  margin-bottom: 50px;
}

.how-proceed__items {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
}

.abitur-directions__cards {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.select-info {
  position: sticky;
  width: 420px;
  height: 100%;
  padding: 0;
  top: 50px;
  margin-right: 20px;
}

.abitur-nav {
  margin: 0;
  max-width: 420px;
  width: 100%;
  height: 100%;
  padding-left: 47px;
  margin-bottom: 50px;
  max-height: calc(100dvh - 100px);
  overflow-y: auto;
  scrollbar-color: rgba(0, 138, 209, 0.3333333333) var(--light-background);
  scrollbar-width: thin;
  scroll-behavior: smooth;
}

.abitur-nav::-webkit-scrollbar {
  width: 10px;
}

.abitur-nav::-webkit-scrollbar-track {
  background: var(--light-background);
}

.abitur-nav::-webkit-scrollbar-thumb {
  background: rgba(0, 138, 209, 0.3333333333);
}

.abitur-nav_edu-direc {
  padding-left: 0;
}

.abitur-nav:last-child {
  margin-bottom: 0;
}

.abitur-nav__item {
  margin-bottom: 15px;
}

.abitur-nav__item:last-child {
  margin-bottom: 0;
}

.abitur-nav__link {
  font-weight: var(--default-text-weight);
  color: var(--buttons-links);
  position: relative;
  display: inline;
  border-bottom: 1px solid transparent;
  transition: 0.3s all ease-in-out;
  text-decoration: none !important;
}

.abitur-nav__link:hover,
.abitur-nav__link_active {
  border-bottom: 1px solid var(--buttons-links);
}

.abitur-faq p {
  margin-bottom: 20px;
  width: 870px;
  padding-left: 50px;
}

.abitur-faq p:last-child {
  margin-bottom: 0;
  padding-bottom: 25px;
}

.abitur-faq details {
  margin-bottom: 0;
  margin-top: 0;
}

.abitur-faq summary:hover > h4 {
  color: var(--text);
}

.abitur-faq_faq-page {
  margin-bottom: 50px;
}

.abitur-faq_faq-page p {
  padding-left: 0;
}

.priem-comp {
  padding-bottom: 50px;
}

.priem-comp__section {
  padding: 50px 0;
}

.priem-comp__section > p {
  margin-bottom: 20px;
}

.priem-comp__section > p:last-child {
  margin-bottom: 0;
}

.priem-comp__section > h4 {
  margin-bottom: 15px;
}

.priem-comp__section:nth-child(4) > h4 {
  margin-top: 0;
  margin-bottom: 20px;
}

.priem-comp__table {
  overflow-x: auto;
}

.priem-comp h1 {
  color: var(--text-light);
  width: 808px;
  margin-bottom: 50px;
}

.priem-comp__subtitle {
  color: var(--text-light);
  margin-bottom: 20px;
}

.priem-comp__text {
  color: var(--text-light);
  margin-bottom: 20px;
}

.priem-comp__link {
  color: var(--text-light);
  position: relative;
}

.priem-comp__link_selection {
  transition: 0.3s all ease-in-out;
  font-weight: var(--default-text-weight);
  line-height: var(--link-lh);
}

.priem-comp__link_selection::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: var(--text-light);
  opacity: 0;
  transition: 0.3s all ease-in-out;
}

.priem-comp__link_selection:hover::before {
  opacity: 1;
}

.priem-comp__group {
  display: flex;
}

.priem-comp__section-title {
  margin-bottom: 50px;
}

.priem-comp__section-title p {
  padding: 0;
  margin: 0;
}

.priem-comp__section-title p strong,
.priem-comp__section-title p b {
  font-weight: var(--regular-text-weight);
}

.priem-comp__priem-places {
  width: 100%;
}

.priem-comp .select-info {
  margin-top: 50px;
}

.priem-header {
  padding: 50px;
  background-color: var(--primary-background);
  position: relative;
  overflow: hidden;
}

.priem-header__group {
  display: flex;
  width: 790px;
  justify-content: space-between;
}

.priem-header__col {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.priem-header__svg {
  position: absolute;
  top: 10%;
  right: 12%;
  overflow: hidden;
}

.priem-header__section {
  margin-top: 50px;
  margin-bottom: 100px;
}

.priem-ul-links {
  margin-top: 7px;
}

.priem-ul-links__item {
  margin-bottom: 25px;
}

.priem-ul-links__item:last-child {
  margin-bottom: 0;
}

.priem-content {
  width: 1320px;
}

.priem-exam,
.priem-info,
.priem-orders,
.priem-docs,
.priem-places,
.priem-questions {
  transition: 0.3s all ease-in-out;
}

.dropdown-list + h4 {
  font-weight: var(--default-text-weight);
  margin-top: 25px;
}

.edu-direc h1 {
  margin: 50px 0;
}

.edu-direc__main-img {
  margin-bottom: 100px;
}

.edu-direc__group {
  display: flex;
  align-items: flex-start;
  margin-bottom: 100px;
}

.edu-direc__content {
  width: 1320px;
}

.schedule-form {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 5px 30px;
}

.schedule-form__group {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.schedule-form__fio {
  display: none;
}

.schedule-form__label {
  margin-bottom: 25px;
}

.schedule-form__select {
  display: none;
}

.schedule-form__submit {
  width: auto;
  margin-bottom: 25px;
  padding: 22px 50px;
}

@media (max-width: 1599.98px) {
  .news-card {
    width: 325px;
  }

  .places-card {
    flex-wrap: wrap;
  }

  .places-card__col-left {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .places-card__col-right {
    width: 100%;
  }

  .direction-card__img {
    display: none;
  }

  .direction-card__info {
    width: 100%;
  }

  .container {
    max-width: 1400px;
  }

  .header {
    margin-bottom: 40px;
  }

  .footer-menu__item {
    margin-right: 67px;
  }

  .footer-links {
    column-gap: 70px;
  }

  .page .container__grid .page__text,
  .page .experiment-resource-page .container__grid p,
  .experiment-resource-page .page .container__grid p,
  .page .pavlov-memory-page .container__grid p,
  .pavlov-memory-page .page .container__grid p,
  .page .history-page .container__grid p,
  .history-page .page .container__grid p,
  .page .container__grid .kolachev-page .grid__col-left p,
  .kolachev-page .grid__col-left .page .container__grid p,
  .page .container__grid .ovz-room-page .grid__col-left p,
  .ovz-room-page .grid__col-left .page .container__grid p,
  .page .container__grid .cources-page .grid__col-left p,
  .cources-page .grid__col-left .page .container__grid p,
  .page .container__grid .contacts .grid__col-left > p,
  .contacts .page .container__grid .grid__col-left > p,
  .page .container__grid .library p,
  .library .page .container__grid p,
  .page .container__grid .memory-card p,
  .memory-card .page .container__grid p {
    width: 100%;
  }

  .page__text,
  .experiment-resource-page .container__grid p,
  .pavlov-memory-page .container__grid p,
  .history-page .container__grid p,
  .kolachev-page .grid__col-left p,
  .ovz-room-page .grid__col-left p,
  .cources-page .grid__col-left p,
  .memory-card p,
  .library p,
  .contacts .grid__col-left > p {
    width: 640px;
  }

  .contacts-page__map {
    height: 540px;
  }

  .history-page .container__grid p {
    width: 100%;
  }

  .experiment-resource-page .container__grid p {
    width: 100%;
  }

  .memory-page h1 {
    padding-bottom: 50px;
  }

  .search__right {
    width: 700px;
  }

  .first-section .grid__col-left {
    height: auto;
  }

  .first-section__main-img {
    max-width: 710px;
  }

  .first-section__sub-images {
    max-width: 170px;
  }

  .first-section__account-link {
    max-width: 210px;
  }

  .grid__col-left {
    width: 710px;
  }

  .grid__col-left_pl {
    padding-left: 90px;
  }

  .grid__col-right {
    width: 670px;
    margin-left: 40px;
  }

  .areas-study__card {
    padding-left: 90px;
  }

  .study-card__title {
    padding-left: 90px;
  }

  .cards__items {
    column-gap: 40px;
  }

  .abitur-header img {
    width: 780px;
  }

  .abitur-page__content {
    width: 1000px;
  }

  .select-info {
    width: 410px;
  }

  .abitur-nav {
    padding-left: 30px;
  }

  .priem-header__svg {
    right: 5%;
  }

  .priem-content {
    width: 1000px;
  }

  .edu-direc__content {
    width: 1000px;
  }

  .edu-direc .abitur-nav {
    padding-left: 0;
  }

  .schedule-form {
    flex-wrap: wrap;
  }
}

@media (max-width: 1399.98px) {
  .main,
  .header,
  .footer {
    margin-right: 0;
  }

  .custom-select {
    width: 243px;
  }

  .news-card {
    width: 225px;
  }

  .honored-card .grid__col-right {
    width: 460px;
    margin-left: 20px;
  }

  .honored-card .grid__col-left {
    width: 480px;
  }

  .honored-card__img {
    width: 460px;
  }

  .direction-card__nums {
    width: auto;
  }

  .direction-card__nums:first-child {
    margin-right: 50px;
  }

  .direction-card__free {
    margin-right: 50px;
  }

  .memory-card picture {
    width: 460px;
  }

  .memory-card .grid__col-right {
    width: 460px;
    margin-left: 20px;
  }

  .schedule-card__name {
    width: 360px;
  }

  .schedule-card__teacher {
    width: 150px;
  }

  .content details details {
    padding-left: 0;
  }

  .content details details table tr {
    display: grid;
    padding: 20px 0;
  }

  .content details details table th {
    width: 100%;
    padding: 0;
  }

  .content details details table td {
    padding: 0;
  }

  .container {
    max-width: 1000px;
  }

  .container__grid {
    margin-left: 0;
  }

  .nav {
    width: 45px;
    height: 45px;
    padding: 0;
    top: 25px;
    right: 20px;
    justify-content: center;
    border-radius: 50%;
    z-index: 10001;
  }

  .nav_inactive {
    display: none;
  }

  .nav__group {
    position: absolute;
    top: 7.5px;
    right: 0;
    width: calc(100vw - 32px);
    padding: 0 7px;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    margin-top: 0;
  }

  .nav__btn {
    margin-top: 3px;
    margin-bottom: 0;
  }

  .nav__btn:first-child {
    margin: 0;
  }

  .nav__btn:not(.nav__btn:first-child) {
    opacity: 0;
    pointer-events: none;
    transition: none;
    visibility: hidden;
  }

  .nav__btn_active {
    opacity: 1 !important;
    pointer-events: all !important;
    transition: 0.3s all ease-in-out !important;
    visibility: visible !important;
  }

  .nav__MAI-logo {
    width: 100px;
  }

  .nav__social-nav {
    position: absolute !important;
    clip: rect(1px 1px 1px 1px);
    /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden;
    visibility: hidden;
  }

  .main-menu-btn {
    margin-top: 0;
  }

  .header {
    padding-top: 25px;
    z-index: 100;
  }

  .header__menu {
    display: none;
    position: absolute;
  }

  .footer__col-contacts {
    margin-left: 40px;
  }

  .footer-menu__item {
    margin-right: 28px;
  }

  .footer-links {
    display: flex;
    flex-wrap: wrap;
  }

  .footer-links__link {
    width: 100%;
  }

  .page h1 {
    padding-bottom: 50px;
  }

  .article-page__info {
    margin-left: 0;
  }

  .article-page__btn-group {
    margin: 0;
    margin-bottom: 100px;
  }

  .page__title {
    padding-bottom: 50px;
  }

  .page__text,
  .experiment-resource-page .container__grid p,
  .pavlov-memory-page .container__grid p,
  .history-page .container__grid p,
  .kolachev-page .grid__col-left p,
  .ovz-room-page .grid__col-left p,
  .cources-page .grid__col-left p,
  .memory-card p,
  .library p,
  .contacts .grid__col-left > p {
    width: 480px;
  }

  .contacts__map {
    height: 400px;
  }

  .contacts .page__subtitle,
  .contacts .ovz-room-page .grid__col-left h3,
  .ovz-room-page .grid__col-left .contacts h3,
  .contacts .cources-page .grid__col-left h3,
  .cources-page .grid__col-left .contacts h3,
  .contacts .memory-card h3,
  .memory-card .contacts h3,
  .contacts .library h3,
  .library .contacts h3,
  .contacts .grid__col-left > h3 {
    margin-top: 50px;
  }

  .metod-compl-page__table {
    overflow-x: auto;
  }

  .kolachev-page .grid {
    flex-wrap: nowrap;
  }

  .kolachev-page .grid__col-left {
    width: 480px;
  }

  .kolachev-page .grid__col-right {
    width: 460px;
    margin-left: 20px;
  }

  .kolachev-page__img {
    width: 460px;
  }

  .history-page .container__grid {
    margin: 0 auto;
  }

  .history-page .container__grid p {
    width: 100%;
  }

  .memory-page h1 {
    padding-bottom: 50px;
  }

  .memory-page h1 + p {
    width: 100%;
  }

  .main-menu {
    width: 100vw;
  }

  .main-menu__logo {
    display: none;
  }

  .main-menu__center,
  .main-menu__left {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  .main-menu__left {
    background-color: var(--dark-blue-background);
    padding: 120px 20px;
    transform: translateX(100%);
  }

  .main-menu__center {
    opacity: 0;
    pointer-events: none;
    transform: translateX(100%);
    z-index: 100001;
    position: relative;
  }

  .main-menu__center_active {
    opacity: 1;
    pointer-events: all;
    transform: scale(1);
  }

  .main-menu__right {
    display: none;
  }

  .main-menu__left_active {
    opacity: 1;
    transform: translate(0px, 0px);
  }

  .menu {
    margin-top: 0;
    width: 50%;
  }

  .menu__item {
    margin-bottom: 50px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .menu__link {
    color: var(--text-light);
    width: 400px;
  }

  .menu__open-submenu {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    color: var(--text-light);
    transition: 0.3s all ease-in-out;
    font-size: var(--pagination-text-size);
  }

  .menu__open-submenu:hover {
    color: var(--text-hover);
  }

  .menu__open-submenu:focus {
    transform: 0.3s all ease-in-out;
    outline: 2px solid #FFF;
  }

  .menu__open-submenu::before {
    content: "→";
  }

  .submenu-back {
    position: fixed;
    top: 25px;
    right: 20px;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--pagination-text-size);
    color: var(--text);
    transform: rotate(180deg);
  }

  .submenu-back:focus {
    transform: 0.3s all ease-in-out;
    outline: 2px solid #000;
  }

  .main-submenu-wrapper {
    margin-top: 70px;
    margin-left: 0;
    padding: 0 20px;
    width: 100%;
    display: block;
    transition: 0.3s all ease-in-out;
    visibility: visible;
  }

  .main-submenu-wrapper_inactive {
    visibility: hidden;
    width: 0;
  }

  .main-submenu-wrapper_small {
    visibility: hidden;
  }

  .main-submenu-wrapper_small_active {
    visibility: visible;
  }

  .main-submenu {
    width: 100%;
    padding: 0 20px;
  }

  .main-submenu__item {
    padding-bottom: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .main-submenu__link {
    width: 300px;
  }

  .main-submenu__open-submenu {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    color: var(--text);
    transition: 0.3s all ease-in-out;
    font-size: var(--pagination-text-size);
  }

  .main-submenu__open-submenu:hover {
    color: var(--text-hover);
  }

  .main-submenu__open-submenu:focus {
    transform: 0.3s all ease-in-out;
    outline: 2px solid #000;
  }

  .menu-info {
    visibility: hidden;
  }

  .visually-impared-menu {
    position: relative;
    margin-top: -162px;
    z-index: 1000000;
    margin-right: 0;
  }

  .visually-impared-menu__group {
    justify-content: flex-start;
    flex-wrap: wrap;
    position: relative;
    gap: 30px;
  }

  .visually-impared-menu__close {
    position: absolute;
    top: 0;
    right: 0;
  }

  .search {
    width: 100%;
  }

  .search__left {
    width: 500px;
  }

  .search__right {
    width: 500px;
  }

  .first-section__main-img {
    max-width: 640px;
  }

  .first-section__sub-images {
    max-width: 152px;
  }

  .first-section__account-link {
    max-width: 190px;
  }

  .admission-rules .grid__col-left {
    width: 100%;
  }

  .admission-rules .grid__col-right {
    position: absolute;
    width: 0;
    height: 0;
  }

  .admission-rules__img {
    position: absolute;
    width: 0;
    height: 0;
  }

  .admission-rules__content h2,
  .admission-rules__content p {
    margin-bottom: 50px;
  }

  .admission-rules__content h3 {
    margin-bottom: 25px;
  }

  .admission-rules__content ul,
  .admission-rules__content ol {
    margin-bottom: 20px;
  }

  .admission-rules__content ul li,
  .admission-rules__content ol li {
    margin-bottom: 0;
  }

  .grid__col-left {
    width: 640px;
  }

  .grid__col-left_pl {
    padding-left: 90px;
  }

  .grid__col-right {
    width: 610px;
    margin-left: 30px;
  }

  .areas-study .grid__col-left {
    width: 100%;
  }

  .areas-study .grid__col-right {
    position: absolute;
    width: 0;
    height: 0;
  }

  .areas-study h2 {
    margin-bottom: 50px;
  }

  .areas-study__img {
    position: absolute;
    width: 0;
    height: 0;
  }

  .areas-study__direction-card {
    margin-bottom: 150px;
  }

  .study-card__direction-info {
    margin-bottom: 50px;
  }

  .study-card__btn {
    margin-bottom: 50px;
  }

  .direction-info__paid,
  .direction-info__cost {
    margin-left: 50px;
  }

  .military-department .grid {
    flex-direction: column;
    padding-left: 90px;
  }

  .military-department .grid__col-left,
  .military-department .grid__col-right {
    width: 100%;
    margin-left: 0;
  }

  .military-department .grid__col-left_pl {
    padding-left: 0;
  }

  .military-department .grid__col-right {
    padding-top: 100px;
  }

  .page-not-found .grid__col-right {
    margin-left: 10px;
    max-width: 700px;
    width: 100%;
  }

  .cards__items {
    column-gap: 30px;
  }

  .abitur-header__items {
    padding: 20px;
    width: 543px;
  }

  .abitur-header picture,
  .abitur-header img {
    width: 417px;
  }

  .abitur-page__content {
    width: 870px;
  }

  .abitur-page .select-info {
    display: none;
  }

  .abitur-courses p {
    width: 100%;
  }

  .select-info {
    position: relative;
    top: 0;
    width: 100%;
    margin: 0;
    margin-bottom: 50px;
  }

  .abitur-nav {
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px 100px;
    padding-left: 0;
  }

  .abitur-nav__item {
    width: 345px;
    margin-bottom: 0;
  }

  .abitur-nav__link:hover,
  .abitur-nav__link_active {
    border-bottom: 1px solid transparent;
  }

  .abitur-faq summary {
    width: 860px;
  }

  .priem-comp__group {
    flex-wrap: wrap;
    width: 100%;
  }

  .priem-comp__group .select-info {
    position: relative;
    width: 100%;
    margin-top: 0;
    top: 0;
  }

  .priem-comp__group .abitur-nav {
    max-width: 100%;
    display: flex;
    padding-left: 0;
    flex-wrap: wrap;
    gap: 30px;
  }

  .priem-comp__group .abitur-nav__item {
    width: 300px;
    margin-bottom: 0;
  }

  .priem-header {
    padding: 50px 30px;
  }

  .priem-header__svg {
    width: 350px;
    height: 350px;
    right: 1%;
    top: 11%;
  }

  .priem-header__svg object {
    width: 100%;
  }

  .priem-content {
    width: 100%;
  }

  .edu-direc__content {
    width: 100%;
  }

  .edu-direc__group {
    flex-wrap: wrap;
  }

  .edu-direc__main-img {
    margin-bottom: 50px;
  }

  .schedule-form {
    column-gap: 10px;
  }
}

@media (max-width: 999.98px) {
  :root {
    --text-nums-size: 1.875rem;
    --default-text-size: 1.375rem;
    --h1-size: 2.5625rem;
    --h2-size: 2.25rem;
    --h3-size: 1.375rem;
  }

  .question-form__row {
    flex-wrap: wrap;
  }

  .question-form__col {
    width: 100%;
  }

  .question-form__textarea {
    margin-top: 25px;
  }

  .news-card {
    width: 193px;
  }

  .abitur-directions-card {
    width: 300px;
    padding: 20px 10px;
  }

  .abitur-directions-card > h4 {
    min-height: 59px;
  }

  .abitur-proceed-card {
    width: 300px;
  }

  .places-card {
    margin-bottom: 20px;
    padding: 10px;
  }

  .honored-card .grid {
    flex-direction: column-reverse;
  }

  .honored-card .grid__col-right {
    width: 100%;
    margin-left: 0;
  }

  .honored-card .grid__col-left {
    width: 100%;
  }

  .honored-card__img {
    width: 100%;
    margin-bottom: 50px;
  }

  .direction-card__desc {
    width: 100%;
  }

  .memory-card .grid__col-left {
    order: 2;
  }

  .memory-card .grid__col-right {
    margin-left: 0;
    order: 1;
  }

  .memory-card picture {
    width: 400px;
    margin-bottom: 30px;
  }

  .schedule-card {
    padding: 10px;
  }

  .schedule-card__day {
    width: 70px;
  }

  .schedule-card__date {
    font-size: 0.75rem;
  }

  .schedule-card__time {
    width: 110px;
  }

  .schedule-card__type {
    width: 45px;
  }

  .schedule-card__name {
    width: 175px;
  }

  .schedule-card__teacher {
    width: 140px;
  }

  .important p {
    width: 100%;
  }

  .important__file {
    width: 100%;
  }

  .important h1 {
    padding-bottom: 50px;
  }

  .container {
    max-width: 640px;
    padding: 0 10px;
    flex-wrap: wrap;
  }

  .container__grid {
    width: 100%;
  }

  .nav {
    top: 20px;
    right: 10px;
  }

  .section {
    margin-bottom: 150px;
  }

  .header {
    padding-top: 20px;
  }

  .footer__divider {
    position: absolute;
    width: 0;
    height: 0;
  }

  .footer__contacts {
    flex-direction: column;
  }

  .footer .grid {
    flex-direction: row;
  }

  .footer__col-contacts {
    margin-left: 0;
    margin-bottom: 30px;
  }

  .footer__col-contacts:last-child {
    margin-bottom: 0;
  }

  .footer-menu {
    display: none;
    position: absolute;
    height: 0;
    width: 0;
  }

  .faculty-page__img {
    margin-top: 50px;
  }

  .page__subtitle,
  .ovz-room-page .grid__col-left h3,
  .cources-page .grid__col-left h3,
  .memory-card h3,
  .library h3,
  .contacts .grid__col-left > h3 {
    margin-top: 50px;
  }

  .page__text,
  .experiment-resource-page .container__grid p,
  .pavlov-memory-page .container__grid p,
  .history-page .container__grid p,
  .kolachev-page .grid__col-left p,
  .ovz-room-page .grid__col-left p,
  .cources-page .grid__col-left p,
  .memory-card p,
  .library p,
  .contacts .grid__col-left > p {
    width: 100%;
  }

  .contacts__map {
    height: 350px;
  }

  .cources-page p {
    width: 100%;
  }

  .cources-page .grid {
    flex-direction: column;
  }

  .cources-page .grid__col-left {
    order: 2;
  }

  .cources-page .grid__col-right {
    order: 1;
    margin-bottom: 50px;
  }

  .ovz-room-page .grid__col-left {
    order: 2;
  }

  .ovz-room-page .grid__col-right {
    order: 1;
    margin-bottom: 30px;
  }

  .metod-compl-page .container > h3 {
    width: 100%;
  }

  .kolachev-page .grid {
    flex-wrap: wrap;
  }

  .kolachev-page .grid__col-left {
    width: 100%;
    order: 2;
  }

  .kolachev-page .grid__col-right {
    width: 100%;
    margin-left: 0;
    order: 1;
  }

  .kolachev-page__img {
    max-width: 400px;
    width: 100%;
    margin-top: 50px;
  }

  .pavlov-memory-page__video {
    height: auto;
  }

  .schedule-page__week {
    width: 100%;
  }

  .schedule-page__group {
    flex-wrap: wrap;
  }

  .schedule-page__teacher {
    margin-left: 0;
    margin-top: 25px;
    width: 100%;
  }

  .schedule-page__desc {
    margin-top: 15px;
  }

  .search-result-block__item {
    width: 100%;
  }

  .modal {
    align-items: flex-start;
    padding-top: 50px;
  }

  .modal__body {
    width: 640px;
    padding: 65px 10px;
  }

  .menu {
    width: 100%;
    padding-right: 0;
  }

  .nav,
  .submenu-back {
    top: 20px;
    right: 10px;
  }

  .visually-impared-menu {
    margin-top: -150px;
  }

  .visually-impared-menu__group {
    column-gap: 40px;
    row-gap: 20px;
  }

  .visually-impared-menu__button,
  .visually-impared-menu__close {
    width: 40px;
    height: 40px;
  }

  .visually-impared-menu__reset {
    font-size: 22px;
  }

  .search__container {
    display: block;
  }

  .search__left {
    height: auto;
  }

  .search__right {
    width: 700px;
    height: auto;
  }

  .search__group {
    margin-top: 0;
  }

  .search__close {
    margin-top: 0;
  }

  .first-section__title {
    margin-top: 60px;
    margin-bottom: 40px;
  }

  .first-section__personal-accounts {
    margin-top: 40px;
    justify-content: start;
    gap: 40px;
  }

  .first-section__buttons {
    gap: 50px;
  }

  .first-section__main-img {
    max-height: 420px;
  }

  .grid {
    flex-direction: column;
  }

  .grid__col-left,
  .grid__col-right {
    width: 100%;
  }

  .grid__col-left_pl {
    padding-left: 0;
  }

  .grid__col-right {
    margin-left: 0;
  }

  .areas-study__card {
    padding-left: 0;
  }

  .areas-study__direction-card {
    margin-bottom: 100px;
    padding-top: 37px;
  }

  .study-card__title {
    padding-left: 0;
  }

  .study-card__id-class {
    bottom: calc(100% + 15px);
  }

  .military-department {
    padding: 50px 0;
  }

  .military-department .grid {
    padding-left: 0;
  }

  .military-department button {
    margin-top: 30px;
  }

  .page-not-found__title {
    margin-top: 0;
  }

  .page-not-found .grid__col-left {
    width: 600px;
    margin: 0 auto;
    margin-top: -60px;
  }

  .page-not-found .grid__col-right {
    margin: 0 auto;
    text-align: center;
  }

  .cards__items {
    column-gap: 20px;
  }

  .abitur-header {
    flex-direction: column;
  }

  .abitur-header__items {
    order: 2;
  }

  .abitur-header picture,
  .abitur-header img {
    width: 100%;
    height: 100%;
  }

  .abitur-header picture {
    order: 1;
  }

  .how-proceed__items {
    gap: 20px;
  }

  .abitur-directions__cards {
    gap: 20px;
  }

  .abitur-nav {
    column-gap: 20px;
  }

  .abitur-nav__item {
    width: 300px;
  }

  .abitur-faq summary {
    width: 100%;
  }

  .abitur-faq p {
    width: 100%;
    padding-left: 30px;
  }

  .priem-comp__group .abitur-nav {
    gap: 20px;
  }

  .priem-comp h1 {
    width: 100%;
    margin-bottom: 30px;
  }

  .priem-comp__table {
    overflow-x: auto;
  }

  .priem-header {
    padding: 50px 15px;
  }

  .priem-header__svg {
    display: none;
  }

  .priem-header__group {
    width: 100%;
  }

  .priem-header__col:first-child {
    width: calc(50% - 10px);
  }

  .schedule-form__label {
    margin-bottom: 10px;
  }
}

@media (max-width: 639.98px) {
  :root {
    --regular-text-size: .875rem;
    --regular-text-small-size: .75rem;
    --default-text-size: 1rem;
    --h1-size: 1.875rem;
    --h2-size: 1.625rem;
  }

  .custom-select {
    width: 100%;
  }

  .btn,
  .filter-btns__btn,
  .content button {
    width: 100%;
    padding: 21px 45px;
    text-align: center;
  }

  .pagination__page,
  .pagination__divider,
  .pagination__prev {
    margin-right: 15px;
  }

  .pagination__next {
    margin-left: 15px;
  }

  .news-card {
    width: 300px;
  }

  .abitur-directions-card {
    width: 100%;
  }

  .abitur-proceed-card {
    width: 100%;
  }

  .abitur-proceed-card__text:last-child {
    margin-bottom: 0;
  }

  .places-card__free,
  .places-card__paid {
    overflow-x: auto;
  }

  .honored-card__img {
    width: 100%;
    margin-bottom: 50px;
  }

  .direction-card__info {
    padding: 30px 10px;
  }

  .direction-card__row {
    margin-bottom: 50px;
    flex-wrap: wrap;
  }

  .direction-card__group {
    width: 100%;
  }

  .direction-card__group:first-child {
    margin-bottom: 30px;
  }

  .direction-card__count {
    margin-bottom: 10px;
  }

  .direction-card__nums:first-child {
    margin-right: 0px;
    margin-bottom: 30px;
  }

  .direction-card__free {
    margin-right: 10px;
  }

  .direction-card__nums:nth-child(1) .direction-card__free {
    margin-right: 46px;
  }

  .direction-card__cols {
    column-gap: 20px;
  }

  .direction-card__edu,
  .direction-card__skills,
  .direction-card__subjects,
  .direction-card__projects,
  .direction-card__work,
  .direction-card__profession {
    width: 300px;
  }

  .memory-card .grid__col-right {
    width: 100%;
  }

  .memory-card picture {
    width: 100%;
  }

  .schedule-card__day {
    width: 77px;
  }

  .schedule-card .schedule-card__group,
  .schedule-card .schedule-card__name,
  .schedule-card .schedule-card__place,
  .schedule-card .schedule-card__teacher,
  .schedule-card .schedule-card__time,
  .schedule-card .schedule-card__type {
    width: 183px;
  }

  .schedule-card .schedule-card__name,
  .schedule-card .schedule-card__teacher,
  .schedule-card .schedule-card__time,
  .schedule-card .schedule-card__type {
    margin-bottom: 10px;
  }

  .schedule-card__time span {
    font-weight: 700;
  }

  .schedule-card__row {
    flex-wrap: wrap;
  }

  .content h3 {
    margin-bottom: 30px;
  }

  .nav {
    top: 10px;
  }

  .nav__MAI-logo {
    width: 100px;
  }

  .section {
    margin-bottom: 100px;
  }

  .article-page__title {
    padding-bottom: 25px;
  }

  .article-page__date {
    margin-bottom: 25px;
  }

  .article-page__main-img {
    margin-bottom: 50px;
  }

  .article-page__prev,
  .article-page__next {
    padding: 22px 9px;
    width: auto;
  }

  .article-page__info img,
  .article-page__info picture {
    margin-bottom: 50px;
  }

  .article-page__info p:last-child,
  .article-page__info a:last-child,
  .article-page__info ul:last-child,
  .article-page__info ol:last-child {
    padding-bottom: 50px;
  }

  .faculty-page__subimg {
    width: 100%;
  }

  .contacts__map {
    height: 300px;
  }

  .contacts .page__subtitle,
  .contacts .ovz-room-page .grid__col-left h3,
  .ovz-room-page .grid__col-left .contacts h3,
  .contacts .cources-page .grid__col-left h3,
  .cources-page .grid__col-left .contacts h3,
  .contacts .memory-card h3,
  .memory-card .contacts h3,
  .contacts .library h3,
  .library .contacts h3,
  .contacts .grid__col-left > h3 {
    margin-top: 30px;
  }

  .contacts .dropdown-list__link {
    justify-content: space-between;
  }

  .contacts .dropdown-list__link h3 {
    width: 250px;
  }

  .contact {
    flex-wrap: wrap;
    width: 100%;
  }

  .contact__title {
    width: 100%;
    margin-bottom: 10px;
  }

  .contact__text {
    width: 100%;
  }

  .history-page__picture-small img {
    width: 100%;
  }

  .search-result-page .search-form__group {
    width: 100%;
  }

  .modal__body {
    width: 320px;
  }

  .visually-impared-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    margin-top: 0;
    background-color: var(--bg-color);
    pointer-events: none;
    opacity: 0;
  }

  .visually-impared-menu__main-title {
    display: block;
    width: 166px;
  }

  .visually-impared-menu__title {
    display: block;
    width: 100%;
    margin-bottom: 15px;
  }

  .visually-impared-menu__group {
    row-gap: 30px;
  }

  .visually-impared-menu__items {
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .visually-impared-menu__reset {
    font-size: 16px;
  }

  .search__logo {
    width: 100px;
  }

  .search__left {
    width: 320px;
    padding: 25px 0;
    margin: 0 auto;
  }

  .search__right {
    width: 320px;
    padding: 25px 0;
    margin: 0 auto;
  }

  .search__close {
    margin-left: 50px;
  }

  .search-form {
    margin-top: 50px;
  }

  .first-section__buttons {
    flex-direction: column;
    gap: 20px;
  }

  .first-section__personal-accounts {
    gap: 0;
    justify-content: space-between;
  }

  .first-section__main-img {
    max-height: 271px;
  }

  .news-card:nth-child(3),
  .news-card:nth-child(4) {
    display: none;
    position: absolute;
    width: 0;
    height: 0;
  }

  .study-card__title {
    line-height: var(--link-lh);
    margin-bottom: 30px;
  }

  .study-card__id-class {
    bottom: calc(100% + 15px);
  }

  .study-card__btn {
    width: 100%;
  }

  .direction-info {
    flex-wrap: wrap;
  }

  .direction-info__paid {
    margin-left: 30px;
  }

  .direction-info__cost {
    width: 100%;
    margin-left: 0;
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
  }

  .military-department button {
    width: 100%;
  }

  .page-not-found .grid__col-left {
    width: 100%;
    margin-top: -20px;
  }

  .page-not-found .grid__col-right {
    max-width: 100%;
  }

  .page-not-found__img {
    padding: 0;
  }

  .cards__items {
    column-gap: 20px;
  }

  .news-page__navigation {
    justify-content: center;
  }

  .abitur-header {
    flex-wrap: wrap;
    margin-bottom: 0;
  }

  .abitur-header__items {
    width: 100%;
    padding: 30px 10px;
  }

  .abitur-page__content {
    width: 100%;
  }

  .how-proceed__items {
    row-gap: 50px;
  }

  .abitur-faq summary {
    width: auto;
    justify-content: space-between;
  }

  .abitur-faq summary h4 {
    width: 256px;
  }

  .abitur-faq summary .small-angle {
    width: 17px;
    margin-left: 0;
  }

  .priem-comp .dropdown-list__link {
    width: 100%;
    justify-content: space-between;
  }

  .priem-comp .dropdown-list__link .angle {
    margin-left: 0;
  }

  .priem-header__group {
    flex-wrap: wrap;
  }

  .priem-header__col {
    width: 100%;
  }

  .priem-header__col:first-child {
    width: 100%;
    margin-bottom: 50px;
  }

  .schedule-form__group,
  .schedule-form__submit {
    width: 100%;
  }
}

@media (max-width: 319.98px) {
  .footer .grid {
    display: flex;
    flex-wrap: wrap;
  }

  .footer .grid__col-left,
  .footer .grid__col-rigth {
    width: 100%;
  }

  .footer__title {
    margin-bottom: 30px;
  }

  .copyright {
    flex-direction: column;
  }

  .copyright__text:last-child {
    margin-top: 30px;
  }

  .first-section h1 {
    text-transform: none;
  }

  .first-section__title {
    margin-top: 50px;
    margin-bottom: 30px;
  }

  .first-section__personal-accounts {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .first-section__account-link {
    max-width: 100%;
  }

  .first-section__main-img {
    max-height: 200px;
  }
}