.form {
    margin-top: 50px;

    &__group {
        position: relative;
    }

    &__input {
        font-size: var(--regular-text-size);
        padding: 21px 30px;
        border: 1px solid var(--input-color);
        width: 100%;
        margin-bottom: 25px;
        color: var(--text);
        outline: none;

        &_error {
            border: 1px solid var(--form-error);
        }
    }

    &__textarea {
        font-size: var(--regular-text-size);
        padding: 19px 30px;
        border: 1px solid var(--input-color);
        width: 100%;
        margin-bottom: 25px;
        min-height: 230px;
        color: var(--text);
        outline: none;
    }

    &__input:focus, &__textarea:focus {
        border: 1px solid var(--buttons-links);
    }

    &__submit {
        width: 100% !important;
    }

    &__info {
        margin-top: 25px;
    }

    &__text-error {
        position: absolute;
        bottom: 9px;
        left: 0;
        color: var(--form-error);
        margin-left: 10px;
        font-size: var(--error-text-size);
    }
}

.question-form {

    &__row {
        display: flex;
        justify-content: space-between;
    }

    &__col {
        width: 400px;
    }

    &__textarea {
        min-height: 235px;
    }

    &__text {
        @extend .regular-text;
    }

    &__btn {
        margin-top: 25px;
    }
}

@media (max-width: $third-bp) {
    .question-form {

        &__row {
            flex-wrap: wrap;
        }

        &__col {
            width: 100%;
        }

        &__textarea {
            margin-top: 25px;
        }
    }
}
