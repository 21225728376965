.text {
    color: var(--text);

    &_important {
        color: var(--important) !important;
    }

    &_bold {
        font-weight: var(--bold-weight);
    }

    &_link {
        @extend .reset-link;
    }
}

.default-text {
    margin: 0;
    font-family: var(--main-font);
    font-size: var(--default-text-size);
    color: var(--text);
    font-weight: var(--default-text-weight);
}

.light-text {
    margin: 0;
    font-family: var(--main-font);
    font-size: var(--default-text-size);
    font-weight: var(--list-text-lh);
    color: var(--text);
}

.regular-text {
    margin: 0;
    font-family: var(--main-font);
    font-size: var(--regular-text-size);
    line-height: var(--regular-text-lh);
    color: var(--text);
}

.regular-small-text {
    margin: 0;
    font-family: var(--main-font);
    font-size: var(--regular-text-small-size);
    color: var(--text);
}
