.container {
    max-width: 1780px;
    width: 100%;
    padding: 0 20px;
    margin: 0 auto;
    // display: flex;

    &_block {
        display: block;
    }

    &_flex {
        display: flex;
    }

    &__grid {
        width: 870px;
        margin-left: 250px;
    }
}

@media (max-width: $first-bp) {

    .container {
        max-width: 1400px;
    }
}

@media (max-width: $second-bp) {

    .container {
        max-width: 1000px;

        &__grid {
            margin-left: 0;
        }
    }
}

@media (max-width: $third-bp) {

    .container {
        max-width: 640px;
        padding: 0 10px;
        flex-wrap: wrap;

        &__grid {
            width: 100%;
        }
    }
}

// @media (max-width: $third-bp) {

//     .container {
//         max-width: 100%;
//     }
// }
