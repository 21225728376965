.h1 {
    margin: 0;
    font-family: var(--main-font);
    font-size: var(--h1-size);
    color: var(--text);
    font-weight: var(--h1-weight);
    line-height: 100%;
}

.h2 {
    margin: 0;
    font-family: var(--main-font);
    font-size: var(--h2-size);
    color: var(--text);
    font-weight: var(--h2-weight);
    line-height: 100%;
}

.h3 {
    margin: 0;
    font-family: var(--main-font);
    font-size: var(--h3-size);
    color: var(--text);
    line-height: var(--h3-lh);
    font-weight: var(--h3-weight);
    line-height: 100%;
}

.h4 {
    margin: 0;
    font-family: var(--main-font);
    font-size: var(--h4-size);
    color: var(--text);
    line-height: var(--h4-lh);
    font-weight: var(--h4-weight);
    line-height: 100%;
}

// @media (max-width: $fourth-bp) {
//     .h1 {
//         font-size: 41px;
//     }

//     .h2 {
//         font-size: 36px;
//     }

//     .h3 {
//         font-size: 22px;
//     }
// }

// @media (max-width: $fifth-bp) {
//     .h1 {
//         font-size: 30px;
//     }

//     .h2 {
//         font-size: 26px;
//     }
// }
